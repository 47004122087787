import { colors } from "../../styles"

export const colorbarsMapping = (index: number) => {
  switch (index) {
    case 0:
      return `${colors.iOrange}`
    case 1:
      return `${colors.iBlue01}`
    case 2:
      return `${colors.iBlue00}`
    case 3:
      return `${colors.iPrimaryBlue}`
    case 4:
      return `${colors.iBlue04}`
    case 5:
      return `${colors.iBlue05}`
    default:
      return `${colors.iWhite}`
  }
}
