import styled from "styled-components"
import { colors } from "../../styles"
import * as T from "../typography"

export const ContentWrapper = styled.div`
  margin-top: 3px;
`

export const VolumeWrapper = styled.div<{ type: "ask" | "bid" }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: ${({ type }) =>
    (type === "ask" && "left") || (type === "bid" && "flex-end")};
`

export const Volume = styled(T.P3)<{ isTOB?: boolean }>`
  color: ${({ isTOB }) => (isTOB ? colors.iWhite : colors.iMediumGrey)};
`
export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin-right: 1em;
  gap: 1px;
`

export const Price = styled(T.P3)<{ isPip?: boolean; isTOB?: boolean }>`
  font-family: ${({ isPip }) => (isPip ? "Lato-Bold" : "Lato-Regular")};
  font-size: ${({ isPip }) => (isPip ? 15 : 13)}px;
  ${({ isTOB }) => (isTOB ? "font-size:23px" : "")};
`

export const FirstPriceWrapper = styled.div<{ type: "bid" | "ask" }>`
  background-color: ${({ type }) =>
    type === "bid" ? colors.iRed : colors.iGreen};
  height: calc(100% - 3px);
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 1.5715;
`

export const ColumnLabels = styled.div`
  padding-top: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 calc((30% + 16px * 3) / 2);
  justify-content: center;
  text-align: center;
  column-gap: 3em;
  position: relative;
`

export const QuotesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 30% 30% 1fr;
  column-gap: 16px;
  row-gap: 5px;
  /* > * {
    border-bottom: 2px solid ${colors.iMediumGrey}26;
  } */
`

export const Spread = styled(T.Notes)`
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  color: ${colors.iMediumGrey};
`

export const ContributorsWrapper = styled.div`
  grid-column-end: span 2;
  display: flex;
  width: 100%;
  place-content: space-between;
`

export const ContributorsNames = styled(T.Notes)`
  color: ${colors.iMediumGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ContributorsExtras = styled(T.Notes)`
  color: ${colors.iWhite};
  margin-right: 0.5em;
`

export const GreyLine = styled.div`
  background-color: ${colors.iMediumGrey}26;
  grid-column-end: span 4;
  height: 2px;
`
