import { Select } from "antd"
import styled from "styled-components"
import * as T from "./../typography"
import { breakpoint, breakpointNum, colors } from "../../styles"
import { fontSizes, globalHeaderStylesTypography } from "../typography"
import { importantize } from "./helpers"
import { ArrowDropDown } from "@styled-icons/material/ArrowDropDown"
import { ReactComponent as Blocks } from "../../icons/blocks.svg"
import { ReactComponent as Hexagon } from "../../icons/hexagon.svg"
import { ReactComponent as Save } from "../../icons/save.svg"
import { ReactComponent as Sweep } from "../../icons/sweep.svg"

export const BlocksStyled = styled(Blocks)`
  color: ${colors.iMediumGrey};
`

export const HexagonStyled = styled(Hexagon)`
  color: ${colors.iPrimaryBlue};
`

export const SaveStyled = styled(Save)`
  color: ${colors.iMediumGrey};
`

export const SweepStyled = styled(Sweep)`
  color: ${colors.iMediumGrey};
`

export const LiquidityDashboardHeaderWrapper = styled.div`
  background-color: ${colors.iUltraDarkGrey};
  margin-top: 2px;
  border-bottom: 1px solid ${colors.iDarkGrey};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  height: fit-content;
  flex-wrap: wrap;
`

export const LiquidityDashboardHeaderRightMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-left: auto;
  @media (max-width: ${breakpointNum.mobile - 1}px) {
    width: 100%;
    place-content: center;
    padding-bottom: 8px;
  }
`

export const LiquidityDashboardHeaderLeftMenu = styled.div`
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: ${breakpointNum.mobile - 1}px) {
    width: 100%;
    place-content: center;
  }
`

export const WrapperHexagon = styled.div<{ isDropdown: boolean }>`
  display: flex;
  flex-direction: row;
  position: absolute;
  padding-top: ${props => (props.isDropdown ? "0.5em" : "0.8em")};
`

export const ArrowDropDownStyled = styled(ArrowDropDown)`
  width: 22px;
  color: ${colors.iWhite};
`

export const StyledSelect = styled(Select)<{ $unnamed?: boolean }>`
  ${importantize(`
  min-width: 100px;
  max-width: 200px;
  padding-left: 7px;
  & > .ant-select-selector {
    background-color: transparent;
  }
  span {
    ${globalHeaderStylesTypography}
    font-size: ${fontSizes.h5}px;
    @media (max-width: ${breakpoint.tablet}) {
      font-size: ${fontSizes.h6}px;
    }
  }
  & .ant-select-dropdown {
    background-color: ${colors.iBlack};
  }
  & .ant-select-dropdown-placement-bottomLeft {
    top: 25px !important;
  }
  & .ant-select-item-option-content {
    color: ${colors.iWhite};
  }
  & .ant-select-item-option-selected {
    color: ${colors.iPrimaryBlue};
    background-color: ${colors.iBlack};
  }
  & .ant-select-item-option-active {
    background-color: ${colors.iUltraDarkGrey};
  }
  & .ant-select-item-option-selected > .ant-select-item-option-content {
    color: ${colors.iPrimaryBlue};
  }
`)}
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  cursor: pointer;
  :hover {
    * {
      fill: ${colors.iPrimaryBlue};
      color: ${colors.iWhite};
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
`

export const HexagonLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`

export const ButtonLabel = styled(T.P3)<{ separatorRight?: boolean }>`
  padding-right: 16px;
  padding-top: 4px;
  border-right: ${props =>
    props.separatorRight
      ? `1px solid ${colors.iDarkGrey}`
      : `0px solid ${colors.iDarkGrey}`};
  color: ${colors.iMediumGrey};
  @media (max-width: ${breakpointNum.mobile - 1}px) {
    border-right-width: 0px;
  }
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const RoundSmallButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const SavedDashboardLabel = styled(T.H5)`
  margin: 10px;
  padding-left: 8px;
  padding-top: 4px;
  color: ${colors.iWhite};
  cursor: pointer;
`

export const NotSavedDashboardLabel = styled(T.P3)`
  margin: 10px;
  padding-left: 8px;
  padding-top: 4px;
  color: ${colors.iMediumGrey};
`
