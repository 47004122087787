import useResizeObserver from "@react-hook/resize-observer"
import axios from "axios"
import { Series } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { z } from "zod"
import { computationalExpensiveGraphs, graphsMenuItems } from "../../constants"
import {
  getBrokerPair,
  getColor,
  getGraphFromType,
  getPair,
  getPairStrip,
  getWeekStart,
  mergeNewData,
  typedKeys,
  zip,
} from "../../helpers"
import indexDb from "../../helpers/indexDB"
import { breakpointNum } from "../../styles"
import {
  BalanceEquityData,
  BalanceEquityTick,
  BrokerPair,
  GraphInfo,
  GraphReactComponent,
  GraphType,
  PairId,
} from "../../types"
import { AuthContext } from "../auth-context"
import BalanceAndEquityMemoizedGraph from "../balance-and-equity-memoized-graph"
import { CostsWidget } from "../costs"
import CustomTooltip from "../tooltip"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import VolumePair from "../volume-by-pair"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { formatExposure, updateSelectBox } from "./helpers"
import {
  BalanceEquityColored,
  BalanceEquityColoredBig,
  BalanceEquityContentWrapper,
  BalanceEquityGrid,
  BalanceEquityLabel,
  BalanceEquityLabelBig,
  BalanceEquityLabelText,
  BalanceEquityRow,
  BalanceEquityRowBig,
  CurrentStatusBottom,
  DotsFlashing,
  LeftBalanceEquityContentWrapper,
  RightBalanceEquityContentWrapper,
} from "./styled"

const defaultDataToShow = {
  timestamp: 0,
  brokerPair: "",
  pnlUSD: 0,
  balance: 0,
  exposure: undefined,
  totalCosts: undefined,
  volume: undefined,
}

const balanceEquityIndexedDBValidator = z.object({
  data: z.array(
    z.object({
      pair: z.string(),
      data: z.array(z.tuple([z.number(), z.number()])),
    }),
  ),
  savedAt: z.number(),
})

const BalanceAndEquity: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const rightContentWrapper = useRef<HTMLDivElement>(null)

  const [pairsListOptions, setPairsListOptions] = useState<
    { sortLabel: string; value: string; label: string }[]
  >([{ sortLabel: "00_ALL", value: "pairs", label: "ALL PAIRS" }])
  const [currentPair, setCurrentPair] = useState<string>("all")

  const { colorsMapper } = useContext(PairsBrokersListContext)

  useResizeObserver(rightContentWrapper, () => {
    chartComponentRef?.current?.chart?.yAxis?.[0]?.update(
      {
        visible: window.innerWidth > breakpointNum.mobile,
      },
      true,
    )
    return window.dispatchEvent(new Event("resize"))
  })

  const tooManyGraphsOfSameType = useMemo(
    () =>
      graphsMenuItems.reduce(
        (acc, item) => ({
          ...acc,
          [item.graph]: item.maxItems
            ? graphs.filter(g => g.type === item.graph).length >= item.maxItems
            : false,
        }),
        {},
      ) as Record<GraphType, boolean>,
    [graphs],
  )

  const [balanceEquityData, setBalanceEquityData] = useState<BalanceEquityData>(
    [defaultDataToShow],
  )
  const { getCurrentUser } = useContext(AuthContext)
  const router = useNavigate()
  const [loadedHistorical, setLoadedHistorical] = useState(false)

  const brokerPair = useMemo(() => getBrokerPair("aggregated", "all"), [])

  const numberOfExpensiveGraphs = useMemo(
    () =>
      graphs.filter(({ type }) => computationalExpensiveGraphs.includes(type))
        .length,
    [graphs],
  )

  useEffect(() => {
    if (!chartComponentRef.current) return

    const throttleTimeInMilliSeconds =
      1_000 * Math.ceil(numberOfExpensiveGraphs / 2)

    const redrawInterval = setInterval(
      () => chartComponentRef.current?.chart.redraw(false),
      throttleTimeInMilliSeconds,
    )
    return () => {
      clearInterval(redrawInterval)
    }
  }, [chartComponentRef, numberOfExpensiveGraphs])

  const balanceEquityListener = useCallback(
    (tick: BalanceEquityTick) => {
      if (tick.brokerPair !== brokerPair) return
      const series: Series[] | undefined =
        chartComponentRef.current?.chart?.series
      const balanceAndStatuses = tick.data

      const nbSeriesPresent = balanceAndStatuses.reduce(
        (acc, { timestamp, brokerPair, pnlUSD, balance }) => {
          const equity = pnlUSD + balance
          const pairStrip = getPairStrip(brokerPair)

          const serie = series?.find(x => x.name === pairStrip)
          if (!serie) {
            chartComponentRef.current?.chart?.addSeries(
              {
                data: [[timestamp, equity]],
                type: "line",
                name: pairStrip,
                color: getColor(colorsMapper, pair.replace("aggregated-", "")),
                showInNavigator: true,
                boostThreshold: 1,
              },
              false,
              false,
            )
            return acc
          } else {
            serie.addPoint(
              [timestamp, +equity.toFixed(2)] as [number, number],
              false,
              false,
            )
            return acc + 1
          }
        },
        0,
      )

      setPairsListOptions(
        updateSelectBox(
          balanceAndStatuses.map(({ brokerPair }) =>
            getPair(brokerPair as BrokerPair),
          ),
        ),
      )

      if (balanceAndStatuses.length === nbSeriesPresent)
        chartComponentRef.current?.chart.redraw()

      setBalanceEquityData(tick.data)
    },
    [brokerPair, colorsMapper, pair],
  )

  useEffect(() => {
    if (!chartComponentRef) return
    const strippedPair = getPairStrip(currentPair)
    chartComponentRef.current?.chart.series.forEach(serie => {
      if (serie.name.includes("Navigator")) return

      serie.setVisible(
        serie.name === strippedPair ||
        (strippedPair === "PAIRS" && serie.name !== "SUM"),
      )
    })
    chartComponentRef.current?.chart.redraw(false)
  }, [currentPair])

  const dataToShow = useMemo(() => {
    const pairToFind = currentPair === "pairs" ? "all" : currentPair
    return (
      balanceEquityData.find(
        ({ brokerPair }) =>
          brokerPair === `aggregated-${pairToFind.toLowerCase()}`,
      ) ?? defaultDataToShow
    )
  }, [balanceEquityData, currentPair])

  useEffect(() => {
    if (!socket) return () => { }
    socket.on("balanceEquity", balanceEquityListener)

    return () => socket.off("balanceEquity", balanceEquityListener)
  }, [socket, graphs, balanceEquityListener])

  useEffect(() => {
    if (loadedHistorical) return
    // this means colors have not been loaded yet
    if (typedKeys(colorsMapper).length === 0) return

    const asyncTrick = async () => {
      const user = await getCurrentUser()
      if (!user.isLogged) {
        router("/login")
        return
      }

      const indexDbKey = `balanceEquity-aggregated-all`

      const loadedSafeData = balanceEquityIndexedDBValidator.safeParse(
        await indexDb.get(indexDbKey),
      )

      let cachedData: z.infer<typeof balanceEquityIndexedDBValidator> = {
        data: [{ pair: "all", data: [] }],
        savedAt: 0,
      }

      if (loadedSafeData.success) {
        cachedData = loadedSafeData.data
      }

      if (cachedData.savedAt < getWeekStart().getTime()) {
        cachedData = {
          data: [{ pair: "all", data: [] }],
          savedAt: 0,
        }
        indexDb.remove(indexDbKey)
      }

      try {
        const { data: res } = await axios.get<{
          data: {
            pair: PairId
            points: [Timestamp: number, equityPoint: number][]
          }[]
          savedAt: number
        }>(
          `${process.env.REACT_APP_ENDPOINT || ""
          }/liquidity-dashboard/balance-and-equity`,
          {
            headers: {
              Authorization: user.tokens.token,
            },
            params: {
              from: cachedData.savedAt,
            },
          },
        )

        const pairList = [
          ...new Set([
            ...cachedData.data.map(({ pair }) => pair),
            ...res.data.map(({ pair }) => pair),
          ]),
        ]
        const historicalData = pairList.map(pair => {
          const oldCachedData =
            cachedData.data.find(({ pair: p }) => p === pair)?.data ?? []
          const newResData =
            res.data.find(({ pair: p }) => p === pair)?.points ?? []

          return {
            pair,
            data: [...oldCachedData, ...newResData],
          }
        })

        setPairsListOptions(
          updateSelectBox(historicalData.map(({ pair }) => pair)),
        )

        indexDb.put(indexDbKey, {
          savedAt: res.savedAt,
          data: historicalData,
        })

        const series: Series[] | undefined =
          chartComponentRef.current?.chart?.series

        historicalData.forEach(({ pair, data }) => {
          const pairStrip = getPairStrip(pair)

          const serie = series?.find(x => x.name === pairStrip)

          if (!serie) {
            chartComponentRef.current?.chart?.addSeries(
              {
                data: data,
                type: "line",
                name: pairStrip,
                color: getColor(colorsMapper, pair),
                showInNavigator: true,
                boostThreshold: 1,
              },
              false,
              false,
            )
          } else {
            serie.setData(
              mergeNewData(
                data,
                zip(
                  (serie as any).xData as number[],
                  (serie as any).yData as number[],
                ),
              ),
              false,
              false,
            )
            serie.update({
              ...serie.options,
              color: getColor(colorsMapper, pair.replace("aggregated-", "")),
            })
          }
        })
        const strippedPair = getPairStrip(currentPair)
        series?.forEach(serie => {
          if (serie.name.includes("Navigator")) return
          serie.setVisible(
            serie.name === strippedPair ||
            (strippedPair === "PAIRS" && serie.name !== "SUM"),
          )
        })
        chartComponentRef.current?.chart.redraw(false)
        setLoadedHistorical(true)
      } catch (err) {
        console.error(err)
      }
    }

    asyncTrick()
  }, [
    broker,
    getCurrentUser,
    router,
    brokerPair,
    currentPair,
    loadedHistorical,
    colorsMapper,
  ])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "balanceEquity",
      id,
      Graph: BalanceAndEquity,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  const costsGraphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "costs",
      id,
      Graph: CostsWidget,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  const volumeGraphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "volumePairs",
      id,
      Graph: VolumePair,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  const openNewGraphFromLabel = (graphInfo: GraphInfo) => {
    if (tooManyGraphsOfSameType?.[volumeGraphInfo.type]) return
    setGraphs([
      ...graphs,
      {
        id: uuidv4(),
        Graph: getGraphFromType(graphInfo.type),
        broker: graphInfo.broker,
        pair: graphInfo.pair,
        type: graphInfo.type,
        vwap: graphInfo.vwap,
        comm: graphInfo.comm,
        rangeMax: graphInfo.rangeMax,
        rangeMin: graphInfo.rangeMin,
      },
    ])
  }

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Current status (USD)"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "select",
            optionsValues: pairsListOptions,
            onChange: value => {
              setCurrentPair(value)
            },
            value:
              pairsListOptions.find(({ value }) => currentPair === value)
                ?.label ?? "",
          },
        ]}
      />
      <BalanceEquityContentWrapper ref={rightContentWrapper}>
        <LeftBalanceEquityContentWrapper>
          <BalanceEquityGrid>
            <BalanceEquityRowBig>
              <BalanceEquityLabelBig>Equity</BalanceEquityLabelBig>
              <BalanceEquityColoredBig
                isZero={
                  dataToShow.balance + dataToShow.pnlUSD < 0.00001 &&
                  dataToShow.balance + dataToShow.pnlUSD > -0.00001
                }
                isNegative={dataToShow.balance + dataToShow.pnlUSD < 0}
              >
                {(dataToShow.balance + dataToShow.pnlUSD).toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                  currencyDisplay: "narrowSymbol",
                })}
              </BalanceEquityColoredBig>
            </BalanceEquityRowBig>
            <BalanceEquityRow>
              <BalanceEquityLabelText>Floating</BalanceEquityLabelText>
              <BalanceEquityColored
                isZero={
                  dataToShow.pnlUSD < 0.00001 && dataToShow.pnlUSD > -0.00001
                }
                isNegative={dataToShow.pnlUSD < 0}
              >
                {dataToShow.pnlUSD.toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                  currencyDisplay: "narrowSymbol",
                })}
              </BalanceEquityColored>
            </BalanceEquityRow>
            <BalanceEquityRow>
              <BalanceEquityLabelText>Balance</BalanceEquityLabelText>
              <BalanceEquityLabel>
                {dataToShow.balance.toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                  currencyDisplay: "narrowSymbol",
                })}
              </BalanceEquityLabel>
            </BalanceEquityRow>
            <CurrentStatusBottom>
              <BalanceEquityRow>
                <CustomTooltip
                  show={tooManyGraphsOfSameType?.[costsGraphInfo.type]}
                  placement={"top"}
                  graphType={costsGraphInfo.type}
                >
                  <BalanceEquityLabelText
                    active={true}
                    onClick={() => openNewGraphFromLabel(costsGraphInfo)}
                    onTouchEnd={() => openNewGraphFromLabel(costsGraphInfo)}
                  >
                    Cost
                  </BalanceEquityLabelText>
                </CustomTooltip>
                {dataToShow.totalCosts !== undefined &&
                  dataToShow.totalCosts !== null ? (
                  <BalanceEquityColored
                    isZero={
                      dataToShow.totalCosts < 0.00001 &&
                      dataToShow.totalCosts > -0.00001
                    }
                    isNegative={dataToShow.totalCosts < 0}
                  >
                    {dataToShow.totalCosts.toLocaleString("en", {
                      style: "decimal",
                      currency: "USD",
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }) + "$pm"}
                  </BalanceEquityColored>
                ) : (
                  <DotsFlashing />
                )}
              </BalanceEquityRow>
              <BalanceEquityRow onMouseDown={e => e.stopPropagation()}>
                <CustomTooltip
                  show={tooManyGraphsOfSameType?.[volumeGraphInfo.type]}
                  graphType={volumeGraphInfo.type}
                >
                  <BalanceEquityLabelText
                    active={true}
                    disabled={tooManyGraphsOfSameType?.[volumeGraphInfo.type]}
                    onClick={() => openNewGraphFromLabel(volumeGraphInfo)}
                    onTouchEnd={() => openNewGraphFromLabel(volumeGraphInfo)}
                  >
                    Volume
                  </BalanceEquityLabelText>
                </CustomTooltip>
                {dataToShow.volume !== undefined &&
                  dataToShow.volume !== null ? (
                  <BalanceEquityLabel>
                    {(dataToShow.volume / 1e6).toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 1,
                      minimumFractionDigits: 1,
                    }) + "m"}
                  </BalanceEquityLabel>
                ) : (
                  <DotsFlashing />
                )}
              </BalanceEquityRow>
              <BalanceEquityRow>
                <BalanceEquityLabelText>Exposure</BalanceEquityLabelText>
                {dataToShow.exposure !== undefined ? (
                  <BalanceEquityLabel>
                    {formatExposure(Math.abs(dataToShow.exposure))}
                  </BalanceEquityLabel>
                ) : (
                  <DotsFlashing />
                )}
              </BalanceEquityRow>
            </CurrentStatusBottom>
          </BalanceEquityGrid>
        </LeftBalanceEquityContentWrapper>
        <RightBalanceEquityContentWrapper className="immovable">
          <BalanceAndEquityMemoizedGraph
            loadedHistorical={loadedHistorical}
            chartComponentRef={chartComponentRef}
          />
        </RightBalanceEquityContentWrapper>
      </BalanceEquityContentWrapper>
    </WidgetWrapper>
  )
}

export default BalanceAndEquity
