import { Button } from "antd"
import styled from "styled-components"
import * as T from "../../components/typography"
import { Alert } from "antd"
import { colors } from "./../../styles"

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 64px);
`

export const TitleLoginForm = styled(T.H2)``

export const AlertStyled = styled(Alert)`
  width: 100%;
  > .ant-alert-description {
    ${T.globalParagraphStylesTypography}
    color: ${colors.iBlack};
  }
`

export const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 20px 20px;
  gap: 1em;
  width: 350px;
`

export const ButtonStyled = styled(Button)`
  margin-top: 15px;
  width: 100px;
`
