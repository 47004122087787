import { useCallback, useEffect, useMemo, useState } from "react"
import { changeGraphComm, getBrokerPair } from "../../helpers"
import {
  GraphInfo,
  GraphReactComponent,
  SpreadAverageData,
  SpreadAveragesTick,
} from "../../types"
import { LetterIcon } from "../letter-icon"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { defaultUnits, getTOBSizePrecision, getunits } from "./helpers"
import {
  RowWrapper,
  SpreadAveragesGridBrokerLabel,
  SpreadAveragesGridHeader,
  SpreadAveragesGridLabel,
  SpreadAveragesGridWrapper,
} from "./styled"

const columnLabels = [
  "BROKER",
  "TOB SPREAD",
  "TOB SIZE",
  "CLIP SIZE",
  "VW SPREAD",
]

const SpreadAverages: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [spreadAveragesData, setSpreadAveragesData] = useState<
    SpreadAverageData[]
  >([])
  const [unit, setUnit] =
    useState<(typeof defaultUnits)[number]["value"]>("pip")

  const spreadAveragesListener = useCallback(
    (tick: SpreadAveragesTick) => {
      const brokerPair = getBrokerPair(broker, pair, comm, vwap)
      if (tick.brokerPair === brokerPair) setSpreadAveragesData(tick.data)
    },
    [broker, comm, pair, vwap],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("spreadAverages", spreadAveragesListener)

    return () => socket.off("spreadAverages", spreadAveragesListener)
  }, [socket, graphs, spreadAveragesListener])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "spreadAverages",
      id,
      Graph: SpreadAverages,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Spread Monitor (averages)"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "toggle",
            value: comm || false,
            onClick: changeGraphComm(graphs, setGraphs, graphInfo),
            Icon: LetterIcon("C"),
            title: comm ? "Remove commission" : "Show commission",
          },
          {
            type: "radio",
            optionsValues: getunits(pair),
            onChange: e => setUnit(e.target.value),
            value: unit,
          },
        ]}
      />
      <SpreadAveragesGridWrapper>
        {columnLabels.map(label => (
          <SpreadAveragesGridHeader key={label}>
            {label}
          </SpreadAveragesGridHeader>
        ))}
        {spreadAveragesData.length > 0 &&
          spreadAveragesData.map(
            (
              {
                broker,
                clipSize,
                VWSpreadDPM,
                VWSpreadPip,
                TOBSizeAverage,
                TOBSpreadAverageDPM,
                TOBSpreadAveragePip,
              },
              index,
            ) => (
              <RowWrapper key={index}>
                <SpreadAveragesGridBrokerLabel title={broker}>
                  {broker}
                </SpreadAveragesGridBrokerLabel>
                <SpreadAveragesGridLabel>
                  {(unit === "pip"
                    ? TOBSpreadAveragePip
                    : TOBSpreadAverageDPM
                  ).toLocaleString("en", {
                    maximumFractionDigits: unit === "pip" ? 3 : 2,
                    minimumFractionDigits: unit === "pip" ? 3 : 2,
                  })}
                </SpreadAveragesGridLabel>
                <SpreadAveragesGridLabel>
                  {TOBSizeAverage.toLocaleString("en", {
                    maximumFractionDigits: getTOBSizePrecision(pair),
                    minimumFractionDigits: getTOBSizePrecision(pair),
                  })}
                </SpreadAveragesGridLabel>
                <SpreadAveragesGridLabel>
                  {clipSize.toLocaleString("en")}
                </SpreadAveragesGridLabel>
                <SpreadAveragesGridLabel>
                  {(unit === "pip" ? VWSpreadPip : VWSpreadDPM).toLocaleString(
                    "en",
                    {
                      maximumFractionDigits: unit === "pip" ? 3 : 2,
                      minimumFractionDigits: unit === "pip" ? 3 : 2,
                    },
                  )}
                </SpreadAveragesGridLabel>
              </RowWrapper>
            ),
          )}
      </SpreadAveragesGridWrapper>
    </WidgetWrapper>
  )
}

export default SpreadAverages
