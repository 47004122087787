import { Broker, DelayDataType, QuotesDelaysData } from "../../types"

export const reshapeData = (
  { data, rowsBrokers }: QuotesDelaysData,
  colsLabels: string[],
): DelayDataType => {
  return {
    data: data,
    rows: rowsBrokers,
    columns: colsLabels,
  }
}

export const reshapeAndFilterBrokers = (
  { data, rowsBrokers }: QuotesDelaysData,
  brokers: Broker[],
  colsLabels: string[],
): DelayDataType => {
  const usedBrokers = brokers.filter(br => br.isUsed).map(br => br.label)
  const indexesUsedBrokers = rowsBrokers.flatMap((broker, idx) =>
    usedBrokers.includes(broker) ? [idx] : [],
  )

  const filteredDelaysData = data.filter(([_x, y, _ts]) =>
    indexesUsedBrokers.includes(y),
  )
  const filteredBrokers = rowsBrokers.filter(rowB => usedBrokers.includes(rowB))

  return {
    data: filteredDelaysData,
    rows: filteredBrokers,
    columns: colsLabels,
  }
}
