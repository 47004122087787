import { typedKeys } from "../../helpers"
import { CurrencyUppercase, ExposureMarginDataType } from "../../types"

export const getUniqueCurrencies = (
  data: ExposureMarginDataType,
): CurrencyUppercase[] => {
  const tradedCurrencies = data.currencies
  let otherStoredCurrencies: CurrencyUppercase[] = []
  if (typedKeys(data.popConf).length > 0) {
    otherStoredCurrencies = typedKeys(data.popConf).reduce(
      (acc, popBroker) => [
        ...acc,
        ...typedKeys(data.popConf[popBroker].margins),
      ],
      [] as CurrencyUppercase[],
    )
  }
  return [...new Set([...tradedCurrencies, ...otherStoredCurrencies])]
}

export const filterOutTierInputs = (valueArray: any) =>
  (valueArray[0] as string).indexOf("tierinput-") === -1

export const isArrayAscending = (a: number[]) =>
  a.slice(1).every((e, i) => e >= a[i])
