import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline"
import { Search } from "@styled-icons/ionicons-outline/Search"
import { Button, Checkbox, Input, Select } from "antd"
import styled from "styled-components"
import { breakpoint, colors } from "../../styles"
import * as T from "../typography"

export const ModalWrapper = styled.div`
  width: 456px;
  height: 614px;
  @media (max-width: ${breakpoint.mobile}) {
    width: 99%;
    height: 99%;
    overflow: hidden;
  }

  background-color: ${colors.iWhite};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  padding: 0 15px 0 15px;
  flex-grow: 1;
  z-index: 13;
`
export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 0;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.iUltraLightGrey};
`
export const ModalHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`
export const ModalTitle = styled(T.P1)`
  color: ${colors.iBlack};
`
export const CloseStyled = styled(CloseOutline)`
  width: 25px;
  height: 25px;
  color: ${colors.iMediumGrey};
  cursor: pointer;
  :hover {
    color: ${colors.iPrimaryBlue};
  }
`
export const DropDownStyled = styled(Select)`
  :hover div {
    border-color: ${colors.iPrimaryBlue} !important;
  }
`
export const BrokerOption = styled(Select.Option)``

export const BrokerOptionGroup = styled(Select.OptGroup)``

export const CheckboxStyled = styled(Checkbox)`
  span input:checked {
    & + span {
      background-color: ${colors.iPrimaryBlue};
      border-color: ${colors.iPrimaryBlue};
    }
  }
`

export const PairIdModalStyled = styled(T.H4)`
  color: ${colors.iBlack};
  width: 80px;
`

export const LabelPairModalStyled = styled(T.P4)`
  color: ${colors.iDarkGrey};
`

export const SectionTitle = styled(T.H4)`
  color: ${colors.iBlack};
  margin: 20px 0 0 0;
`

export const PairsOptionsList = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 0;
`

export const PairOptionItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  gap: 10px;
  padding: 8px 0;
  padding-left: 8px;
  border-bottom: 1px solid ${colors.iUltraLightGrey};
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  :hover {
    background-color: ${colors.iPrimaryBlue + "33"};
  }
  background-color: ${({ disabled }) =>
    disabled ? colors.iUltraLightGrey + "60" : colors.iWhite};
  p,
  h4 {
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  }
`

export const PairSearchBox = styled(Input)``

export const SearchIcon = styled(Search)`
  width: 18px;
  color: ${colors.iMediumGrey};
`

export const StyledButton = styled(Button)`
  margin: 0 0 0 8px;
  ${({ type }) =>
    type === "primary"
      ? `
      background-color: ${colors.iPrimaryBlue};
      border-color: ${colors.iPrimaryBlue};  
      :focus {
        background-color: ${colors.iPrimaryBlue};
        border-color: ${colors.iPrimaryBlue};  
      }
      :hover {
      background-color: ${colors.iBlue04};
      border-color: ${colors.iBlue04};  
    }
    `
      : `
    :hover, :focus {
      color: ${colors.iPrimaryBlue};
      border-color: ${colors.iPrimaryBlue};  
    }
    `}
`

export const ModalFooter = styled.div`
  height: 44px;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: 10px 0;
  margin-top: calc(auto + 10px);
`

export const SpinnerWrapper = styled.div`
  flex-flow: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
