import { v4 as uuidv4 } from "uuid"
import {
  getFirstValidBroker,
  getGraphFromType,
  getGraphOptions,
} from "../../helpers"
import { Broker, GraphsList, GraphType, PairId } from "../../types"

export const addGraphsWithOnlyAggregatedOptions = (
  setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>,
  brokersList: Broker[],
  graphType: GraphType,
) => {
  const graphOptions = getGraphOptions(graphType)
  const defaultBroker = getFirstValidBroker(graphOptions, brokersList)

  setGraphs(oldGraphs => [
    ...oldGraphs,
    {
      id: uuidv4(),
      Graph: getGraphFromType(graphType),
      broker: defaultBroker,
      pair: "all" as PairId,
      type: graphType,
      vwap: false,
      comm: false,
    },
  ])
  return
}
