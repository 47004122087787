import { Radio } from "antd"
import { SubheaderOption } from "../../types"
import {
  GraphButtonCircle,
  OptionStyled,
  SelectStyled,
  RadioStyled,
} from "./styled"

export const renderOptions = <V,>(
  option: SubheaderOption<V>,
  i: number,
): JSX.Element => {
  switch (option.type) {
    case "toggle":
      return (
        <GraphButtonCircle
          isActive={option.value}
          onClick={option.onClick}
          onMouseDown={e => e.stopPropagation()}
          key={"WidgetSubheaderOptions" + i}
        >
          <option.Icon className={"icon"} title={option.title} />
        </GraphButtonCircle>
      )
    case "select":
      return (
        <SelectStyled
          key={"WidgetSubheaderSelect" + i}
          filterOption={option.filterOption}
          showSearch
          value={option.value}
          onChange={option.onChange as any}
          onMouseDown={e => e.stopPropagation()}
          disabled={option.disabled}
        >
          {option.optionsValues.map(({ value, label }, idx) => (
            <OptionStyled key={`option-select${i}-${idx}`} value={value}>
              {label ?? value}
            </OptionStyled>
          ))}
        </SelectStyled>
      )
    case "radio":
      return (
        <Radio.Group
          onChange={option.onChange}
          value={option.value}
          key={"WidgetSubheaderRadio" + i}
        >
          {option.optionsValues.map((val, j) => (
            <RadioStyled value={val.value} key={val.value + val.label + i + j}>
              {val.label ?? val.value}
            </RadioStyled>
          ))}
        </Radio.Group>
      )
  }
}
