import useResizeObserver from "@react-hook/resize-observer"
import axios from "axios"
import Highcharts, { Series } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import _ from "lodash"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { z } from "zod"
import {
  deleteAllAnnotations,
  getBrokerPair,
  getPairFromId,
  getWeekStart,
  setGraphExtremesAndRedrawChart,
} from "../../helpers"
import indexDb from "../../helpers/indexDB"
import { AIActivityData, GraphInfo, GraphReactComponent } from "../../types"
import MemoizedAIActivityGraphOHLC from "../ai-activity-memoized-graph-ohlc-second-proposal"
import MemoizedAIActivityGraph from "../ai-activity-memoized-graph-second-proposal"
import { AuthContext } from "../auth-context"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { computeMarkersPosition, generateMarker } from "./helpers"
import { AiWrapper } from "./styled"

const AiActivityIndexedDBValidator = z.object({
  data: z.array(
    z.object({
      timestamp: z.number(),
      open: z.number(),
      close: z.number(),
      max: z.number(),
      min: z.number(),
      sizeBuy: z.number(),
      sizeSell: z.number(),
      tradeSize: z.number(),
      avgPriceBuy: z.number(),
      avgPriceSell: z.number(),
    }),
  ),
  savedAt: z.number(),
})

const candlestickOptions = [
  {
    value: 1,
    label: "1 MINUTE",
  },
  {
    value: 5,
    label: "5 MINUTES",
  },
  {
    value: 60,
    label: "1 HOUR",
  },
]

const displayOptions = [
  {
    value: "net",
    label: "NET",
  } as const,
  { value: "all", label: "BUY/SELL" } as const,
]

const graphTypeOptions = [
  {
    value: "candles",
    label: "CANDLES",
  } as const,
  { value: "ohlc", label: "OHLC" } as const,
]

const AIActivityGraph: GraphReactComponent = ({
  graphs,
  setGraphs,
  broker,
  pair,
  id,
  vwap,
  comm,
  rangeMin,
  rangeMax,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [historicalData, setHistoricalData] = useState<AIActivityData[]>()
  const [chunkSizeMinutes, setChunkSizeMinutes] = useState<number>(5)
  const [display, setDisplay] =
    useState<(typeof displayOptions)[number]["value"]>("net")
  const [graphType, setGraphType] = useState<"candles" | "ohlc">("candles")
  const { getCurrentUser } = useContext(AuthContext)
  const precision = useMemo(() => getPairFromId(pair).bpPrecision, [pair])
  const brokerPair = getBrokerPair(broker, pair)
  const [graphExtremes, setGraphExtremes] = useState<{
    min: number
    max: number
  }>({ min: rangeMin ?? Date.now() - 60_000, max: rangeMax ?? Date.now() })

  const wrapperRef = useRef<HTMLDivElement>(null)
  useResizeObserver(wrapperRef, () => {
    window.dispatchEvent(new Event("resize"))
    Highcharts.fireEvent(
      chartComponentRef?.current?.chart?.xAxis[0],
      "afterSetExtremes",
    )
  })

  const setExtremes =
    useCallback<Highcharts.AxisSetExtremesEventCallbackFunction>(
      async _e => {
        if (!chartComponentRef?.current) return []
        if (!historicalData) return []

        const xAxisExtremes =
          chartComponentRef.current?.chart.xAxis[0].getExtremes()

        // COMPUTE INTERVALS CLASSIFICATIONS
        const markerPositions: [number, number, number][] = _.chunk(
          historicalData,
          chunkSizeMinutes,
        )
          .filter(
            chunk =>
              chunk.length === chunkSizeMinutes &&
              chunk.at(-1)!.timestamp < xAxisExtremes.max &&
              chunk[0].timestamp > xAxisExtremes.min,
          )
          .flatMap(computeMarkersPosition(display))

        if (!chartComponentRef?.current) return
        if (!markerPositions) return

        deleteAllAnnotations(chartComponentRef?.current.chart)

        // DRAW MARKERS CODE
        markerPositions
          .map(
            generateMarker(
              chartComponentRef.current?.chart.yAxis[0],
              chunkSizeMinutes,
            ),
          )
          .forEach(marker =>
            chartComponentRef.current?.chart.addAnnotation(marker, false),
          )
        // REDRAW THE CHART
        setGraphExtremes({ min: xAxisExtremes.min, max: xAxisExtremes.max })
        chartComponentRef.current?.chart.redraw(false)
      },
      [chunkSizeMinutes, historicalData, display],
    )

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 10)
  }, [])

  useEffect(() => {
    if (!chartComponentRef?.current) return
    Highcharts.fireEvent(
      chartComponentRef?.current?.chart?.xAxis[0],
      "afterSetExtremes",
    )
  }, [display])

  //Draw candles
  useEffect(() => {
    if (!historicalData) return
    if (!chartComponentRef.current?.chart) return

    // PRICES HANDLING AND DRAW CANDLESTICKS
    const dataToPlot = _.chunk(historicalData, chunkSizeMinutes)
      .filter(chunk => chunk.length === chunkSizeMinutes)
      .map(chunk => ({
        x: chunk[Math.floor(chunkSizeMinutes / 2)].timestamp,
        open: chunk[0].open,
        high: Math.max(...chunk.map(p => p.max)),
        low: Math.min(...chunk.map(p => p.min)),
        close: chunk.at(-1)?.close ?? 0,
        custom: {
          buySize: _.sum(chunk.map(p => p.sizeBuy)),
          sellSize: _.sum(chunk.map(p => p.sizeSell)),
          tradeSize: chunk[0].tradeSize,
          avgPriceBuy:
            _.sum(chunk.map(p => p.sizeBuy * p.avgPriceBuy)) /
              _.sum(chunk.map(p => p.sizeBuy)) || 0,
          avgPriceSell:
            _.sum(chunk.map(p => p.sizeSell * p.avgPriceSell)) /
              _.sum(chunk.map(p => p.sizeSell)) || 0,
        },
      }))

    const series: Series | undefined =
      chartComponentRef.current?.chart?.series?.filter(
        s => s.options.className !== "highcharts-navigator-series",
      )?.[0]
    series?.setData(dataToPlot, false, false, false)

    // REDRAW THE CHART
    if (rangeMin && rangeMax)
      setGraphExtremesAndRedrawChart(chartComponentRef, rangeMin, rangeMax)
    else chartComponentRef.current?.chart.redraw(false)
  }, [chunkSizeMinutes, historicalData, graphType, rangeMin, rangeMax])

  //Get data from backend
  useEffect(() => {
    const asyncTrick = async () => {
      try {
        const url =
          (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
          "/liquidity-dashboard/ai-activity"

        const user = await getCurrentUser()
        if (!user.isLogged) return

        const indexDbKey = `aiActivity-${brokerPair}`

        const loadedSafeData = AiActivityIndexedDBValidator.safeParse(
          await indexDb.get(indexDbKey),
        )

        let cachedData: z.infer<typeof AiActivityIndexedDBValidator> = {
          data: [],
          savedAt: 0,
        }
        if (loadedSafeData.success) {
          cachedData = loadedSafeData.data
        }

        if (cachedData.savedAt < getWeekStart().getTime()) {
          cachedData = { data: [], savedAt: 0 }
          indexDb.remove(indexDbKey)
        }

        const { data: res } = await axios.get<{
          data: AIActivityData[]
          savedAt: number
        }>(url, {
          params: {
            pair,
            broker: broker.id,
            from: cachedData.savedAt,
          },
          headers: {
            Authorization: user.tokens.token,
          },
        })

        const historicalData = [...cachedData.data, ...res.data]
        await indexDb.put(indexDbKey, {
          savedAt: res.savedAt,
          data: historicalData,
        })

        setHistoricalData(historicalData)
      } catch (err) {
        console.error(err)
      }
    }

    asyncTrick()
    const interval = setInterval(asyncTrick, 60_000)
    return () => clearInterval(interval)
  }, [broker, pair, chartComponentRef, getCurrentUser, brokerPair])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "aiActivity-2",
      id,
      Graph: AIActivityGraph,
      vwap,
      comm,
      rangeMin: graphExtremes.min,
      rangeMax: graphExtremes.max,
    }),
    [broker, comm, id, pair, vwap, graphExtremes.min, graphExtremes.max],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="AI Activity Graph - Second proposal"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "select",
            optionsValues: graphTypeOptions,
            value:
              graphTypeOptions.find(({ value }) => graphType === value)
                ?.label ?? "",
            onChange: value => {
              setGraphType(value)
            },
          },
          {
            type: "radio",
            optionsValues: displayOptions,
            onChange: e => setDisplay(e.target.value),
            value: display,
          },
          {
            type: "select",
            optionsValues: candlestickOptions,
            value:
              candlestickOptions.find(({ value }) => chunkSizeMinutes === value)
                ?.label ?? "",
            onChange: value => {
              setChunkSizeMinutes(value)
            },
          },
        ]}
      />
      <AiWrapper
        className="immovable"
        ref={wrapperRef}
        onMouseDown={e => e.stopPropagation()}
      >
        {graphType === "candles" ? (
          <MemoizedAIActivityGraph
            chartComponentRef={chartComponentRef}
            precision={precision}
            chunkSizeMinutes={chunkSizeMinutes}
            setExtremes={setExtremes}
          />
        ) : (
          <MemoizedAIActivityGraphOHLC
            chartComponentRef={chartComponentRef}
            precision={precision}
            chunkSizeMinutes={chunkSizeMinutes}
            setExtremes={setExtremes}
          />
        )}
      </AiWrapper>
    </WidgetWrapper>
  )
}

export default AIActivityGraph
