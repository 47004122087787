import { Button, Space } from "antd"
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import Marquee from "react-fast-marquee"
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom"
import { Socket } from "socket.io-client"
import { AuthContext } from "../components/auth-context"
import PairsBrokersListContext from "../components/used-pairs-brokers-context"
import { pairList } from "../constants"
import LiquidityDashboard from "../pages/liquidity-dashboard"
import UserLogin from "../pages/login"
import { Monitoring } from "../pages/monitoring"
import { OrderBlotter } from "../pages/order-blotter"
import PasswordRecovery from "../pages/password-recovery/"
import { Prices } from "../pages/prices"
import { Reports } from "../pages/reports"
import { Settings } from "../pages/settings"
import UserSignUp from "../pages/signup"
import DefaultLayout from "../templates/defaultLayout"
import { Broker, BrokerId, PairId } from "../types"
import { AlarmType, connectToSocket, settingSocketOn } from "./helpers"
import {
  AlarmStyled,
  AlarmTextStyled,
  AlertStyled,
  ButtonWFT,
  DisconnectAlertText,
} from "./styled"

const pages = [
  {
    link: "/",
    component: LiquidityDashboard,
    protected: true,
  },
  {
    link: "order-blotter",
    component: OrderBlotter,
    protected: true,
  },
  {
    link: "/reports",
    component: Reports,
  },
  {
    link: "/prices",
    component: Prices,
  },
  {
    link: "/monitoring",
    component: Monitoring,
    protected: true,
  },
  {
    link: "/settings",
    component: Settings,
    protected: true,
  },
  {
    link: "/login",
    component: UserLogin,
  },
  {
    link: "/password-recovery",
    component: PasswordRecovery,
  },
  {
    link: "/signup",
    component: UserSignUp,
    protected: true,
  },
]

const RedirectToLogin = () => {
  const navigate = useNavigate()
  useEffect(() => navigate("/login"), [navigate])
  return null
}

const Router = () => {
  const { getCurrentUser } = useContext(AuthContext)
  const [isLogged, setIsLogged] = useState<boolean>()
  const [socket, setSocket] = useState<Socket>()
  const ENDPOINT = useMemo(
    () => process.env.REACT_APP_ENDPOINT || "http://localhost:4000",
    [],
  )
  const [pairsBrokersList, setPairsBrokersList] = useState<
    [PairId, BrokerId][]
  >([])
  const [tradedPairs, setTradedPairs] = useState<PairId[]>([])
  const [brokersList, setBrokersList] = useState<Omit<Broker, "label">[]>([])
  const [disconnectAlert, setDisconnectAlert] = useState(false)
  const [alarms, setAlarms] = useState<AlarmType>({
    enabled: false,
    text: "",
    isSevere: false,
  })
  const [colorsData, setColorsData] = useState<Record<string, string>>({})

  useEffect(() => {
    getCurrentUser().then(user => setIsLogged(user.isLogged))
  }, [getCurrentUser, setIsLogged])

  useEffect(() => {
    if (!socket) return
    const socketOff = settingSocketOn(socket, setDisconnectAlert)
    return socketOff
  }, [socket])

  useLayoutEffect(() => {
    connectToSocket(
      getCurrentUser,
      socket,
      setSocket,
      ENDPOINT,
      setPairsBrokersList,
      setTradedPairs,
      setBrokersList,
      setAlarms,
      setColorsData,
    )
    return () => {
      socket?.off()
    }
  }, [ENDPOINT, getCurrentUser, socket])

  return isLogged !== undefined ? (
    <PairsBrokersListContext
      pairsBrokersList={pairsBrokersList}
      tradedPairsId={
        tradedPairs.length > 0 ? tradedPairs : pairList.map(x => x.id)
      }
      brokersList={brokersList}
      colorsMapper={colorsData}
    >
      <BrowserRouter>
        <Routes>
          {pages.map((page, index) => (
            <Route
              key={index}
              path={page.link}
              element={
                !page?.protected || isLogged ? (
                  <DefaultLayout>
                    {alarms.enabled && (
                      <AlarmStyled
                        banner
                        type={alarms.isSevere ? "error" : "warning"}
                        isSevere={alarms.isSevere}
                        showIcon={false}
                        message={
                          <Marquee pauseOnHover gradient={false}>
                            <AlarmTextStyled isSevere={alarms.isSevere}>
                              {alarms.text}
                            </AlarmTextStyled>
                          </Marquee>
                        }
                        action={
                          <Space>
                            <ButtonWFT
                              type="primary"
                              onClick={() =>
                                window.open(
                                  "https://wft.intellis.ch/",
                                  "_blank",
                                )
                              }
                            >
                              Go to WFT
                            </ButtonWFT>
                          </Space>
                        }
                      />
                    )}
                    {disconnectAlert && (
                      <AlertStyled
                        banner
                        action={
                          <Space>
                            <Button
                              size="small"
                              onClick={() => window.location.reload()}
                            >
                              Click to refresh
                            </Button>
                          </Space>
                        }
                        message={
                          <Marquee pauseOnHover gradient={false}>
                            <DisconnectAlertText>
                              It seems we have disconnected from the socket.
                              Data might not be up-to-date. Please refresh the
                              page.
                            </DisconnectAlertText>
                          </Marquee>
                        }
                      />
                    )}
                    <page.component socket={socket} />
                  </DefaultLayout>
                ) : (
                  <RedirectToLogin />
                )
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </PairsBrokersListContext>
  ) : null
}

export default Router
