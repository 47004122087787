import { Form, InputNumber, Spin } from "antd"
import axios from "axios"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { ExposureStartingBalanceDataType } from "../../types"
import { AuthContext } from "../auth-context"

import { Alert } from "antd"
import { typedEntries } from "../../helpers"
import { parseReceivedData } from "./helpers"
import {
  FormItemStyled,
  FormScrollable,
  FormStyled,
  SettingGridCellRight,
  SettingsButtonStyled,
  SettingsGrid,
  SettingsGridCell,
  SettingsGridHeader,
  SettingsGridRow,
  SettingsHeaderLabel,
  TitleSettingsCard,
  TitleSettingsWrapper,
  WrapperSettingsCard,
} from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/starting-balances"

const SettingsStartingBalanceCard = () => {
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const { getCurrentUser } = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [allData, setAllData] = useState<ExposureStartingBalanceDataType>()
  const [responseError, setResponseError] = useState<boolean>()
  const [responseSuccess, setResponseSuccess] = useState<boolean>()
  const [defaultValues, setDefaultValues] = useState<{}>({})
  const [totalDeposit, setTotalDeposit] = useState<number>(0)

  const getStartingBalanceData = useCallback(
    () =>
      getCurrentUser()
        .then(user => {
          if (!user.isLogged) return
          axios
            .get<ExposureStartingBalanceDataType>(url, {
              headers: {
                Authorization: user.tokens.token,
              },
            })
            .then(({ data }) => {
              setAllData(data)
              setIsLoading(false)

              const formReceivedData = parseReceivedData(data)

              setDefaultValues(formReceivedData.formKeys)
              setTotalDeposit(formReceivedData.depositAmount)
            })
        })
        .catch(console.error),
    [getCurrentUser],
  )

  useEffect(() => {
    setIsLoading(true)
    getStartingBalanceData()
  }, [getStartingBalanceData])

  const onFinish = async (values: any) => {
    const dataToSend = {
      popConf: typedEntries(values).reduce(
        (acc, valueArray) => {
          return { ...acc, [valueArray[0]]: valueArray[1] }
        },
        {} as Record<string, number>,
      ),
    }
    const user = await getCurrentUser()
    if (!user.isLogged) return
    await axios
      .post(url, dataToSend, {
        headers: {
          Authorization: user.tokens.token,
        },
      })
      .then(res => {
        if (allData) {
          const newAllData = {
            ...allData,
            popConf: res.data,
          }
          const formReceivedData = parseReceivedData(newAllData)

          setAllData(newAllData)
          setResponseSuccess(true)
          setDefaultValues(formReceivedData.formKeys)
          setTotalDeposit(formReceivedData.depositAmount)
        } else throw new Error("Something went wrong")
      })
      .catch(err => {
        console.error(err)
        setResponseError(true)
      })
  }

  useEffect(() => {
    setResponseSuccess(false)
    setResponseError(false)
  }, [])

  return (
    <WrapperSettingsCard isLoading={isLoading}>
      {isLoading ? (
        <Spin />
      ) : (
        <div className="immovable">
          <TitleSettingsWrapper>
            <TitleSettingsCard>
              Starting Balance Configuration
            </TitleSettingsCard>
          </TitleSettingsWrapper>
          <FormScrollable>
            <FormStyled
              name="balanceForm"
              layout={"horizontal"}
              onFinish={onFinish}
              form={form}
              ref={formRef}
              requiredMark={false}
              initialValues={defaultValues}
            >
              <SettingsGrid>
                <SettingsGridHeader key="firstheader">
                  <SettingsHeaderLabel key={`PopBroker-header`}>
                    POP/Broker
                  </SettingsHeaderLabel>
                  <SettingsHeaderLabel key={`StartingBalance-header`}>
                    Starting Balance
                  </SettingsHeaderLabel>
                </SettingsGridHeader>

                {allData &&
                  allData.popBrokers.map((brokername, index) => {
                    return (
                      <SettingsGridRow key={index}>
                        <SettingsGridCell key={`${index}-row`}>
                          {brokername}
                        </SettingsGridCell>
                        <SettingsGridCell key={`${index}-startingbal`}>
                          <FormItemStyled
                            label={null}
                            name={brokername}
                            colon={false}
                            rules={[
                              {
                                required: true,
                                message: "Please input a starting balance!",
                              },
                            ]}
                          >
                            <InputNumber
                              controls={false}
                              formatter={value => {
                                if (value === undefined) return "0"
                                return new Intl.NumberFormat("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  maximumFractionDigits: 0,
                                  minimumFractionDigits: 0,
                                }).format(Number(value))
                              }}
                            />
                          </FormItemStyled>
                        </SettingsGridCell>
                      </SettingsGridRow>
                    )
                  })}
                <SettingsGridRow>
                  <SettingsGridCell key={`totaldeposit`}>
                    Total Deposit
                  </SettingsGridCell>
                  <SettingGridCellRight key={`totaldepositvalue`}>
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      maximumFractionDigits: 0,
                      minimumFractionDigits: 0,
                    }).format(Number(totalDeposit))}
                  </SettingGridCellRight>
                </SettingsGridRow>
              </SettingsGrid>
              <FormItemStyled {...{ wrapperCol: { span: 24, offset: 0 } }}>
                <SettingsButtonStyled type="primary" htmlType="submit">
                  Submit
                </SettingsButtonStyled>
              </FormItemStyled>
            </FormStyled>
          </FormScrollable>
          {responseSuccess ? (
            <Alert message="Data successfully updated" type="success" />
          ) : null}
          {responseError ? (
            <Alert message="Unfortunately an error occurred" type="error" />
          ) : null}
        </div>
      )}
    </WrapperSettingsCard>
  )
}

export default SettingsStartingBalanceCard
