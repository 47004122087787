import { Button } from "antd"
import styled from "styled-components"
import * as T from "../../components/typography"
import { Alert } from "antd"
import { colors } from "../../styles"

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 64px);
`

export const TitleLoginForm = styled(T.H2)``

export const AlertStyled = styled(Alert)`
  width: 100%;
  > .ant-alert-description {
    ${T.globalParagraphStylesTypography}
    color: ${colors.iBlack};
  }
`

export const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 20px 20px;
  gap: 10px;
  width: 350px;
`

export const ButtonStyled = styled(Button)`
  ${T.globalParagraphStylesTypography}

  width: 100px;
  background-color: ${colors.iPrimaryBlue};
  border-radius: 2px;
  border-color: transparent;

  :hover {
    background-color: ${colors.iBlue04};
    border-color: transparent;
  }
`

export const ForgottenPasswordLink = styled(T.P3)`
  cursor: pointer;
  width: 100%;
  text-align: right;
  :hover {
    color: ${colors.iPrimaryBlue};
  }
  margin-top: 10px;
`
