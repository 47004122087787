import axios from "axios"
import { JwtValidateResponse, UserTokens } from "../../types"

const url = process.env.REACT_APP_LOGIN_ENDPOINT || ""

export const refreshTokens = (refreshToken: UserTokens["refreshToken"]) =>
  axios
    .post(`${url}/api/jwt/refresh`, {
      refreshToken,
    })
    .then(
      res =>
        res.data as {
          refreshToken: string
          refreshTokenId: string
          token: string
        },
    )

export const validateToken = (token: UserTokens["token"]) =>
  axios
    .get(`${url}/api/jwt/validate`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(res => res.data as JwtValidateResponse)
