import { Button } from "antd"
import styled from "styled-components"
import { colors } from "../../styles"
import * as T from "../typography"

export const WrapperSettingsCard = styled.div`
  background-color: ${colors.iUltraDarkGrey};
  border-radius: 4px;
  width: min-content;
  min-width: 250px;
  height: 100%;
  min-height: 130px;
  padding: 15px;
`

export const TitleSettingsWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  color: ${colors.iWhite};
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const TitleSettingsCard = styled(T.H3)``

export const SettingsButtonStyled = styled(Button)`
  ${T.globalParagraphStylesTypography};
  width: 89px;
  opacity: 0.8;
  border-radius: 2px;
  background: ${colors.iRed};
  :active,
  :hover {
    opacity: 1;
    background: ${colors.iRed}!important;
  }
  :disabled {
    opacity: 0.8;
    background-color: ${colors.iTransparent}!important;
    color: ${colors.iLightGrey}!important;
  }
`

export const ResetLabel = styled(T.P2)``

export const ContentWrapper = styled.div`
  flex-direction: row;
  display: flex;
  padding: 0.5em;
  justify-content: space-between;
`

export const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const RightWrapper = styled.div``
