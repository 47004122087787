import RGL, { WidthProvider } from "react-grid-layout"
import styled from "styled-components"
import { colors } from "../../styles"

export const MonitoringCardsWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 26px;
  flex-direction: row;
  flex-wrap: wrap;
`

const ResponsiveGridLayout = WidthProvider(RGL)
export const RGLStyled = styled(ResponsiveGridLayout)<{ width: number }>`
  margin: 8px 0;
  position: relative;
  width: ${({ width }) => width}px;
  > .react-grid-placeholder {
    background-color: ${colors.iUltraDarkGrey}6b;
    z-index: 1;
  }
`
