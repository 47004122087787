import styled from "styled-components"
import { breakpointNum, colors } from "../../styles"
import * as T from "../typography"

export const WftBrokerOrPopTableGridHeader = styled(T.Notes)`
  color: ${colors.iMediumGrey};
  text-align: right;
  :first-child {
    text-align: left;
  }
  :nth-child(2) {
    text-align: center;
  }
  white-space: nowrap;
`

export const WftBrokerOrPopTableGridWrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 1fr 1fr 1fr 1fr 1.1fr 0.9fr;
  grid-row-gap: 5px;
  grid-column-gap: 10px;
  padding: 10px 2px;
  @media (max-width: ${breakpointNum.mobile}px) {
    grid-column-gap: 5px;
  }
`

export const WftBrokerOrPopTableCellCenter = styled(T.P4)`
  color: white;
  text-align: center;
  @media (max-width: ${breakpointNum.mobile}px) {
    font-size: 9px;
  }
`

export const WftBrokerOrPopTableCellLeft = styled(T.P4)`
  color: white;
  text-align: left;
  min-width: 72px;
  @media (max-width: ${breakpointNum.mobile}px) {
    font-size: 9px;
  }
`

export const WftBrokerOrPopTableCellRight = styled(T.P4)`
  color: white;
  text-align: right;
  @media (max-width: ${breakpointNum.mobile}px) {
    font-size: 9px;
  }
`

export const SpinnerWrapper = styled.div`
  flex-flow: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WftBrokerOrPopTableCellTotalCenter = styled(
  WftBrokerOrPopTableCellCenter,
)`
  font-weight: bold;
  margin-top: 15px;
`

export const WftBrokerOrPopTableCellTotalLeft = styled(
  WftBrokerOrPopTableCellLeft,
)`
  font-weight: bold;
  margin-top: 15px;
`

export const WftBrokerOrPopTableCellTotalRight = styled(
  WftBrokerOrPopTableCellRight,
)`
  font-weight: bold;
  margin-top: 15px;
`
