import useResizeObserver from "@react-hook/resize-observer"
import axios from "axios"
import { Series } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { z } from "zod"
import { computationalExpensiveGraphs } from "../../constants"
import {
  getBrokerPair,
  getGraphData,
  getPairFromId,
  getWeekStart,
  mergeNewData,
  zip,
} from "../../helpers"
import indexDb from "../../helpers/indexDB"
import { colors } from "../../styles"
import { GraphInfo, GraphReactComponent, SpreadTick } from "../../types"
import { AuthContext } from "../auth-context"
import MemoizedSpreadGraph from "../spread-memoized-graph/"
import * as T from "../typography"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { Dot, LegendWrapper, SpreadWrapper } from "./styled"

const spreadIndexedDBValidator = z.object({
  data: z.array(z.array(z.tuple([z.number(), z.number()]))),
  savedAt: z.number(),
})

const SpreadGraph: GraphReactComponent = ({
  graphs,
  setGraphs,
  socket,
  broker,
  pair,
  id,
  vwap,
  comm,
  rangeMin,
  rangeMax,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const brokerPair = getBrokerPair(broker, pair)
  const precision = useMemo(() => getPairFromId(pair).bpPrecision, [pair])
  const [loadedHistorical, setLoadedHistorical] = useState(false)
  const [graphExtremes, setGraphExtremes] = useState<{
    min: number
    max: number
  }>({ min: rangeMin ?? Date.now() - 60_000, max: rangeMax ?? Date.now() })
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 10)
  }, [])

  const numberOfExpensiveGraphs = useMemo(
    () =>
      graphs.filter(({ type }) => computationalExpensiveGraphs.includes(type))
        .length,
    [graphs],
  )

  useResizeObserver(wrapperRef, () => window.dispatchEvent(new Event("resize")))

  useEffect(() => {
    if (!chartComponentRef.current) return

    const throttleTimeInMilliSeconds =
      1_000 * Math.ceil(numberOfExpensiveGraphs / 2)
    const redrawInterval = setInterval(
      () => chartComponentRef.current?.chart.redraw(false),
      throttleTimeInMilliSeconds,
    )
    return () => {
      clearInterval(redrawInterval)
    }
  }, [chartComponentRef, numberOfExpensiveGraphs])

  const { getCurrentUser } = useContext(AuthContext)
  useEffect(() => {
    const asyncTrick = async () => {
      const url =
        (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
        "/liquidity-dashboard/spread"

      const user = await getCurrentUser()
      if (!user.isLogged) return

      const indexDbKey = `spread-${brokerPair}`
      try {
        const loadedSafeData = spreadIndexedDBValidator.safeParse(
          await indexDb.get(indexDbKey),
        )
        let cachedData: z.infer<typeof spreadIndexedDBValidator> = {
          data: [],
          savedAt: 0,
        }
        if (loadedSafeData.success) {
          cachedData = loadedSafeData.data
        }
        if (cachedData.savedAt < getWeekStart().getTime()) {
          cachedData = { data: [[], []], savedAt: 0 }
          indexDb.remove(indexDbKey)
        }

        const { data: res } = await axios.get<{
          savedAt: number
          data: SpreadTick["data"]
        }>(url, {
          params: {
            pair,
            broker: broker.id,
            from: cachedData.savedAt,
          },
          headers: {
            Authorization: user.tokens.token,
          },
        })

        const historicalData = zip(cachedData.data, res.data).map(
          ([cachedSeries, resSeries]) => [...cachedSeries, ...resSeries],
        )
        indexDb.put(indexDbKey, {
          savedAt: res.savedAt,
          data: historicalData,
        })

        const graphData = getGraphData(chartComponentRef) || []

        zip(historicalData, graphData).forEach(([resData, data], index) => {
          chartComponentRef.current?.chart.series[index].setData(
            mergeNewData(resData, data),
            false,
            false,
            false,
          )
        })
      } catch (err) {
        console.error(err)
      }

      // if we are passing both rangeMin and rangeMax
      if (rangeMin && rangeMax) {
        const shift = rangeMax - rangeMin
        const dataMin = (chartComponentRef.current?.chart.series[0] as any)
          .xData[0]
        const dataMax = (
          chartComponentRef.current?.chart.series[0] as any
        ).xData.at(-1)

        const graphMin = Math.max(rangeMin, dataMin)
        const proposedMax = Math.max(graphMin + shift, rangeMax)
        const graphMax = Math.min(proposedMax, dataMax)
        chartComponentRef.current?.chart.xAxis[0].setExtremes(
          graphMin,
          graphMax,
          true,
          false,
        )
      } else chartComponentRef.current?.chart.redraw(false)
      setLoadedHistorical(true)
    }
    asyncTrick()
  }, [
    broker,
    pair,
    chartComponentRef,
    getCurrentUser,
    brokerPair,
    rangeMax,
    rangeMin,
  ])

  const spreadListener = useCallback(
    (tick: SpreadTick) => {
      if (tick.brokerPair !== brokerPair) return

      const series: Series[] | undefined =
        chartComponentRef.current?.chart?.series?.filter(
          s => s.options.className !== "highcharts-navigator-series",
        )

      zip(series || [], tick.data).forEach(([serie, data]) => {
        data.forEach(([timestamp, val]) => {
          serie?.addPoint(
            [timestamp, Number(val.toFixed(precision + 2))] as [number, number],
            false,
            false,
          )
        })
      })
    },
    [brokerPair, precision],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("spread", spreadListener)

    return () => socket.off("spread", spreadListener)
  }, [socket, spreadListener])

  const setExtremes =
    useCallback<Highcharts.AxisSetExtremesEventCallbackFunction>(
      e => {
        if (e.trigger !== "syncExtremes") {
          if (!loadedHistorical) return
          setGraphExtremes({ min: e.min, max: e.max })
          e.preventDefault()
        }
      },
      [loadedHistorical],
    )

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "spread",
      id,
      Graph: SpreadGraph,
      vwap,
      comm,
      rangeMin: graphExtremes.min,
      rangeMax: graphExtremes.max,
    }),
    [broker, comm, id, pair, vwap, graphExtremes.min, graphExtremes.max],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Spread Graph"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <SpreadWrapper
        className="immovable"
        ref={wrapperRef}
        onMouseDown={e => e.stopPropagation()}
      >
        <LegendWrapper>
          <Dot color={colors.iRed} />
          <T.P3>50000 ticks average</T.P3>
          <Dot color={colors.iPrimaryBlue} />
          <T.P3>100 ticks average</T.P3>
        </LegendWrapper>
        <MemoizedSpreadGraph
          chartComponentRef={chartComponentRef}
          precision={precision}
          loadedHistorical={loadedHistorical}
          setExtremes={setExtremes}
          rangeMin={rangeMin}
          rangeMax={rangeMax}
        />
      </SpreadWrapper>
    </WidgetWrapper>
  )
}

export default SpreadGraph
