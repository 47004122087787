import styled from "styled-components"
import { colors } from "../../styles"
import * as T from "../typography"

export const ComparisonCardWrapper = styled.div<{ isLoading?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.iUltraDarkGrey};
  width: 100%;
  min-width: 250px;
  height: 100%;
  padding: 20px;
  ${({ isLoading }) =>
    isLoading
      ? `display: flex;
      align-items: center;
      justify-content: center;`
      : ``}
`

export const ComparisonCardTitleWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 9px;
  border-bottom: 1px solid ${colors.iMediumGrey};
`

export const ComparisonCardTitle = styled(T.H3)``

export const WebFxContentWrapper = styled.div``

export const WebFXHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 2fr 2fr 2fr;
  width: 100%;
  column-gap: 12px;
  row-gap: 5px;
  margin-bottom: 1.5em;
`

export const ComparisonHeaderLabel = styled(T.H4)<{
  left?: boolean
  center?: boolean
  right?: boolean
}>`
  color: ${colors.iWhite};
  text-align: center;
  ${({ left }) => left && "text-align: left;"}
  ${({ center }) => center && "text-align: center;"};
  ${({ right }) => right && "text-align: right;"};
  padding-top: 0.2em;
`

export const ComparisonText = styled(T.P3)<{
  color?: string
  left?: boolean
  center?: boolean
  right?: boolean
}>`
  color: ${({ color }) => color};
  ${({ left }) => left && "text-align: left;"}
  ${({ center }) => center && "text-align: center;"};
  ${({ right }) => right && "text-align: right;"};
`

export const ComparisonRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 2fr 2fr 2fr;
  width: 100%;
  padding: 0.2em;
  height: 1.7em;
  align-items: center;
  :hover {
    background-color: ${colors.iDarkGrey};
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

export const BottomText = styled(T.P3)`
  color: ${colors.iMediumGrey};
  text-align: right;
  width: 100%;
  margin-top: 1em;
`
