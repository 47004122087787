import styled from "styled-components"

export const PepWrapper = styled.div`
  flex-grow: 1;
  flex-basis: auto;
  overflow: hidden;
`

export const PriceGraphWrapper = styled.div`
  height: calc(33% - 10px);
`

export const ExposureGraphWrapper = styled.div`
  height: calc(33% - 20px);
`

export const PNLGraphWrapper = styled.div`
  height: calc(33% + 30px);
`
