import { Alert, Button } from "antd"
import styled from "styled-components"
import { CloseOutline } from "styled-icons/evaicons-outline"
import { colors } from "../../styles"
import * as T from "./../typography"

export const SaveLayoutModalWrapper = styled.div`
  position: absolute;
  width: 460px;
  height: fit-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: ${colors.iWhite};
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  padding: 0 15px 0 15px;
  flex-grow: 1;
  z-index: 13;
`

export const SaveLayoutModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 0;
  justify-content: space-between;
`

export const SaveLayoutModalHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`

export const SaveLayoutModalHeaderTitle = styled(T.P1)`
  color: ${colors.iBlack};
  padding-bottom: 3px;
`

export const CloseStyled = styled(CloseOutline)`
  width: 25px;
  height: 25px;
  fill: ${colors.iMediumGrey};
  cursor: pointer;
`

export const InputSupTitle = styled(T.H5)`
  color: ${colors.iBlack};
  padding-bottom: 6px;
`

export const SaveLayoutModalContent = styled.div`
  padding-top: 21px;
  padding-bottom: 51px;
  border-top: 1px solid ${colors.iUltraLightGrey};
`

export const SaveLayoutModalFooter = styled.div`
  justify-content: flex-end;
  display: flex;
  height: 53px;
  padding-top: 11px;
  border-top: 1px solid ${colors.iUltraLightGrey};
`

export const StyledButton = styled(Button)`
  margin: 0 0 0 8px;
  ${({ type }) =>
    type === "primary"
      ? `
      , :focus {
        background-color: ${colors.iPrimaryBlue};
        border-color: ${colors.iPrimaryBlue};  
      }
      :hover {
      background-color: ${colors.iBlue04};
      border-color: ${colors.iBlue04};  
    }
    `
      : `
    :hover, :focus {
      color: ${colors.iPrimaryBlue};
      border-color: ${colors.iPrimaryBlue};  
    }
    `}
`
export const StyledAlert = styled(Alert)`
  margin-top: 6px;
`
