import { Spin } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import {
  changeGraphComm,
  getBrokerPair,
  getIntervalLabel,
  getPairFromId,
} from "../../helpers"
import {
  GraphInfo,
  GraphReactComponent,
  TOBContributionData as TOBContributionDataType,
  TOBContributionTick,
} from "../../types"
import { LetterIcon } from "../letter-icon"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { colorbarsMapping } from "./helpers"
import {
  BarBackground,
  BarValue,
  BarWrapper,
  HeaderUpdatesLabel,
  HeaderUpdatesValue,
  HeaderUpdatesWrapper,
  HeaderWrapper,
  RowWithBar,
  RowWithBarLabel,
  RowWithBarsWrapper,
  SpinnerWrapper,
  Value,
} from "./styled"

export const TOBContribution: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [TOBContributionData, setTOBContributionData] =
    useState<TOBContributionDataType>()

  const liquidityListener = useCallback(
    (tick: TOBContributionTick) => {
      const brokerPair = getBrokerPair(broker, pair, comm, vwap)
      if (tick.brokerPair === brokerPair)
        setTOBContributionData({
          ...tick.data,
          broker: tick.broker,
          pair: getPairFromId(tick.pair),
        })
    },
    [broker, comm, pair, vwap],
  )

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "TOBContribution",
      id,
      Graph: TOBContribution,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("TOBContribution", liquidityListener)

    return () => socket.off("TOBContribution", liquidityListener)
  }, [socket, graphs, liquidityListener])

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="TOB Contribution"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "toggle",
            value: comm || false,
            onClick: changeGraphComm(graphs, setGraphs, graphInfo),
            Icon: LetterIcon("C"),
            title: comm ? "Remove commission" : "Show commission",
          },
        ]}
      />

      <HeaderWrapper>
        <RowWithBar>
          <RowWithBarLabel
            style={{ textAlign: "left", textOverflow: "ellipsis" }}
          >
            Contributions
          </RowWithBarLabel>
          <BarWrapper>
            <BarBackground>
              <BarValue
                value={100 * (TOBContributionData?.numberOfContributions || 0)}
              />
            </BarBackground>
          </BarWrapper>
          <Value>
            {`${(
              100 * (TOBContributionData?.numberOfContributions || 0)
            ).toFixed(2)}%`}
          </Value>
        </RowWithBar>

        <HeaderUpdatesWrapper>
          <HeaderUpdatesLabel># of updates:</HeaderUpdatesLabel>
          <HeaderUpdatesValue>
            {TOBContributionData?.tickNumber.toLocaleString("en")}
          </HeaderUpdatesValue>
        </HeaderUpdatesWrapper>
      </HeaderWrapper>

      <RowWithBarsWrapper>
        {TOBContributionData ? (
          TOBContributionData.volumeDistribution.map(
            ({ threshold, contributionPercentage }, index) => (
              <RowWithBar
                key={`bar-contribution-${threshold[0]}-${threshold[1]}-${index}`}
              >
                <RowWithBarLabel>{getIntervalLabel(threshold)}</RowWithBarLabel>
                <BarWrapper>
                  <BarBackground>
                    <BarValue
                      value={100 * contributionPercentage}
                      backgroundColor={colorbarsMapping(index)}
                    />
                  </BarBackground>
                </BarWrapper>
                <Value>{`${(100 * contributionPercentage).toFixed(2)}%`}</Value>
              </RowWithBar>
            ),
          )
        ) : (
          <SpinnerWrapper>
            <Spin />
          </SpinnerWrapper>
        )}
      </RowWithBarsWrapper>
    </WidgetWrapper>
  )
}
