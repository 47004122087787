import { useEffect, useRef, memo, useMemo } from "react"
import { WidgetWrapper } from "../widget-wrapper"
import WidgetHeader from "../widget-header"
import { GraphInfo, GraphReactComponent } from "../../types"

const TradingViewWidget: GraphReactComponent = ({
  graphs,
  setGraphs,
  broker,
  pair,
  id,
}) => {
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!container.current) return
    const script = document.createElement("script")
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js"
    script.type = "text/javascript"
    script.async = true
    script.innerHTML = `
        {
          "autosize": true,
          "symbol": "FX:EURUSD",
          "interval": "30",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "1",
          "locale": "en",
          "allow_symbol_change": true,
          "calendar": false,
          "support_host": "https://www.tradingview.com"
        }`
    container.current.appendChild(script)
  }, [])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "externalPrices",
      id,
      Graph: TradingViewWidget,
    }),
    [broker, id, pair],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Trading View Prices"
      />
      <div
        className="tradingview-widget-container"
        ref={container}
        style={{ height: "100%", width: "100%" }}
      >
        <div
          className="tradingview-widget-container__widget"
          style={{ height: "calc(100% - 32px)", width: "100%" }}
        />
      </div>
    </WidgetWrapper>
  )
}

export default memo(TradingViewWidget)
