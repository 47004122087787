import { Spin } from "antd"
import axios from "axios"
import _ from "lodash"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getPairFromId } from "../../helpers"
import { CompareStateResponse } from "../../types"
import { AuthContext } from "../auth-context"
import { percentageToColor } from "./helpers"
import {
  BottomText,
  ComparisonCardTitle,
  ComparisonCardTitleWrapper,
  ComparisonCardWrapper,
  ComparisonHeaderLabel,
  ComparisonRowWrapper,
  ComparisonText,
  WebFxContentWrapper,
  WebFXHeaderWrapper,
} from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/webFx/compare-state"

const MonitoringWebFxComparisson: React.FC<{
  compare: "balance" | "numberOfDeals"
}> = ({ compare }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [data, setData] = useState<CompareStateResponse>([])
  const { getCurrentUser } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    getCurrentUser()
      .then(user => {
        if (!user.isLogged) {
          navigate("/login")
          return
        }
        return axios
          .get<CompareStateResponse>(url, {
            headers: {
              Authorization: user.tokens.token,
            },
          })
          .then(({ data }) => {
            if (!data.length) return
            const pairwiseData = Object.values(_.groupBy(data, d => d.importId))
              .sort((a, b) => b[0].timestamp - a[0].timestamp)[0]
              .filter(x => x.type === compare)
              .sort((a, b) => (a.pair > b.pair ? 1 : -1))
            const aggregatedPair: CompareStateResponse[number] = {
              id: 123,
              timestamp: Date.now(),
              type: compare,
              importId: 123,
              webFx: _.sum(pairwiseData.map(d => d.webFx)),
              fspotter: _.sum(pairwiseData.map(d => d.fspotter)),
              pair: "all",
              broker: "aggregated",
            }
            setData([...pairwiseData, aggregatedPair])
          })
      })
      .then(() => setIsLoading(false))
      .catch(e => {
        console.error(e)
      })
  }, [compare, getCurrentUser, navigate])

  if (isLoading)
    return (
      <ComparisonCardWrapper isLoading>
        <Spin />
      </ComparisonCardWrapper>
    )

  const title =
    compare === "balance"
      ? "WebFX Comparison (Balance USD)"
      : "WebFX Comparison (# Deals)"
  const lastUpdate = new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "numeric",

    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(data[0]?.timestamp ?? 0)

  return (
    <ComparisonCardWrapper>
      <ComparisonCardTitleWrapper>
        <ComparisonCardTitle>{title}</ComparisonCardTitle>
      </ComparisonCardTitleWrapper>
      <WebFxContentWrapper>
        <WebFXHeaderWrapper>
          {["Pair", "WebFX", "FSpotter", "% Difference"].map(label => (
            <ComparisonHeaderLabel
              key={`${compare}-${label}-header`}
              right={["WebFX", "FSpotter", "% Difference"].includes(label)}
            >
              {label}
            </ComparisonHeaderLabel>
          ))}
        </WebFXHeaderWrapper>
        {Object.values(data).flatMap((d, idx) => {
          const percentage = ((d.fspotter - d.webFx) / d.fspotter) * 100

          const significantDigits = compare === "balance" ? 2 : 0
          const format = Intl.NumberFormat("en", {
            maximumFractionDigits: significantDigits,
            minimumFractionDigits: significantDigits,
          }).format

          return [
            <ComparisonRowWrapper key={`${compare}-${idx}-row}`}>
              <ComparisonText key={`${compare}-${idx}-a`} center>
                {getPairFromId(d.pair).label}
              </ComparisonText>
              <ComparisonText key={`${compare}-${idx}-b`} right>
                {format(d.webFx)}
              </ComparisonText>
              <ComparisonText key={`${compare}-${idx}-c`} right>
                {format(d.fspotter)}
              </ComparisonText>
              <ComparisonText
                key={`${compare}-${idx}-d`}
                color={percentageToColor(percentage)}
                right
              >
                {percentage.toFixed(2)}
              </ComparisonText>
            </ComparisonRowWrapper>,
          ]
        })}
      </WebFxContentWrapper>
      <BottomText>Last Update: {lastUpdate}</BottomText>
    </ComparisonCardWrapper>
  )
}

export default MonitoringWebFxComparisson
