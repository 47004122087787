import socketIOClient, { Socket } from "socket.io-client"
import { typedEntries } from "../helpers"
import { Broker, BrokerId, PairId, ReactSetter, User } from "../types"

export type AlarmType = {
  enabled: boolean
  text: string
  isSevere: boolean
}

export const settingSocketOn = (
  socket: Socket,
  setDisconnectAlert: ReactSetter<boolean>,
) => {
  const connect = () => {
    console.log("Hey we are connected")
    setDisconnectAlert(false)
  }
  const connectError = (err: Error) => {
    console.log("connect_error", err)
    setDisconnectAlert(true)
  }
  const disconnect = (reason: Socket.DisconnectReason) => {
    console.log("It seems we have disconnected", reason)
    setDisconnectAlert(true)
  }

  socket.on("connect", connect)
  socket.on("connect_error", connectError)
  socket.on("disconnect", disconnect)

  return () => {
    socket.off("connect", connect)
    socket.off("connect_error", connectError)
    socket.off("disconnect", disconnect)
  }
}

export const connectToSocket = (
  getCurrentUser: () => Promise<User>,
  socket: Socket | undefined,
  setSocket: ReactSetter<Socket | undefined>,
  ENDPOINT: string,
  setPairsBrokersList: ReactSetter<[PairId, BrokerId][]>,
  setTradedPairs: ReactSetter<PairId[]>,
  setBrokersList: ReactSetter<Omit<Broker, "label">[]>,
  setAlarms: ReactSetter<AlarmType>,
  setColorsData: ReactSetter<Record<string, string>>,
) => {
  getCurrentUser().then(user => {
    if (socket === undefined && user.isLogged) {
      setSocket(
        socketIOClient(ENDPOINT, {
          path: "/ws/",
          forceNew: true,
          query: {
            token: user.tokens.token || "",
          },
        }),
      )
    }
  })
  socket?.on("pairs-brokers", (pairsBrokers: [PairId, BrokerId, number][]) => {
    setPairsBrokersList(
      //A list of all the broker paris received by the backend
      pairsBrokers.map(([pair, broker, _ts]) => [pair, broker]),
    )
  })
  socket?.on("traded-pairs", (tradedPairs: PairId[]) => {
    //A list of activelly traded pairs, taken from tradincConf.tradeSizes
    setTradedPairs(tradedPairs)
  })
  socket?.on("brokers-list", (brokersList: Omit<Broker, "label">[]) => {
    //List of all brokers received by the backend
    setBrokersList(brokersList)
  })
  socket?.on(
    "alarm",
    (alarms: {
      statusConnection: Record<string, { quote: boolean; trade: boolean }>
      errors: { message: string; severe: boolean }[]
    }) => {
      const disconnections = typedEntries(alarms.statusConnection)
        .filter(([_, { quote, trade }]) => !quote || !trade)
        .map(([key, { quote, trade }]) => {
          return `${key} has quote ${quote ? "online" : "offline"} and trade ${
            trade ? "online" : "offline"
          }`
        })

      const errors = alarms.errors.map(({ message }) => `${message}`)

      const newAlarm: AlarmType = {
        enabled: disconnections.length > 0 || alarms.errors.length > 0,
        text: [...disconnections, ...errors].join(" - "),
        isSevere:
          disconnections.length > 0 ||
          alarms.errors.some(({ severe }) => severe),
      }

      setAlarms(newAlarm)
    },
  )
  socket?.on("colors-data", (colorsData: Record<string, string>) => {
    setColorsData(colorsData)
  })
}
