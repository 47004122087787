import HighchartsReact from "highcharts-react-official"

export const onMouseMove =
  (graphs: React.RefObject<HighchartsReact.RefObject>[]) =>
  (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    graphs.forEach(graph => {
      const normalizedEvent = graph?.current?.chart?.pointer?.normalize(
        e.nativeEvent,
      )
      if (!normalizedEvent) return

      const graphPoint = graph?.current?.chart?.series?.[0]?.searchPoint(
        normalizedEvent,
        true,
      )

      if (!graphPoint) return

      graphPoint.setState("hover")
      graph?.current?.chart?.tooltip?.refresh(graphPoint, normalizedEvent)
      graph?.current?.chart?.xAxis?.[0]?.drawCrosshair(
        normalizedEvent,
        graphPoint,
      )
    })
  }

export const onMouseLeave =
  (graphs: React.RefObject<HighchartsReact.RefObject>[]) =>
  (_e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    graphs.forEach(graph => {
      graph?.current?.chart?.series?.[0]?.setState("inactive", true)

      graph?.current?.chart?.xAxis?.[0]?.drawCrosshair(undefined)

      graph?.current?.chart?.tooltip?.hide()
    })
  }

export const computeGraphExtremes = (
  chartRef: React.RefObject<HighchartsReact.RefObject>,
) => {
  const dataMin = (chartRef.current?.chart.series[0] as any).xData[0]
  const dataMax = (chartRef.current?.chart.series[0] as any).xData.at(-1)
  const { userMin, userMax } = (
    chartRef.current?.chart.xAxis[0] as any
  ).getExtremes() // the one set using props, might be out of bound
  const graphMin = Math.max(dataMin, userMin)
  const shift = userMax - userMin
  const proposedMax = Math.max(graphMin + shift, userMax)
  const graphMax = Math.min(proposedMax, dataMax)
  return {
    min: graphMin,
    max: graphMax,
  }
}

const formatter = Intl.NumberFormat("en", {
  notation: "compact",
  minimumFractionDigits: 1,
  maximumFractionDigits: 3,
})

export const formatVolume = (volume: number) => formatter.format(volume)
