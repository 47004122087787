import Header from "../../components/header"
import { Wrapper, Main } from "./styled"

const DefaultLayout = ({ children }: any) => {
  return (
    <Wrapper>
      <Header />
      <Main>{children}</Main>
    </Wrapper>
  )
}

export default DefaultLayout
