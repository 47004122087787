import { AnnotationsOptions, Axis } from "highcharts"
import _ from "lodash"
import { colors } from "../../styles"
import { AIActivityData } from "../../types"

const VISIBLE_MARKERS_THRESHOLD = 12
const RATIO_MARKER_VS_CANDLEWITH = 1

const chunkClassification = (deltaSize: number, tradeSize: number) =>
  _.toInteger(deltaSize / tradeSize)

const getAvgPriceMarkerColor = (classify: "buy" | "sell") => {
  switch (classify) {
    case "buy":
      return colors.iGreenText
    case "sell":
      return colors.iRedText
  }
}

export const computeMarkersPosition =
  (display: "net" | "all") => (chunk: AIActivityData[]) => {
    const ts =
      chunk[0].timestamp + (chunk.at(-1)!.timestamp - chunk[0].timestamp) / 2
    const high = Math.max(...chunk.map(p => p.max))
    const low = Math.min(...chunk.map(p => p.min))

    const buySize = _.sum(chunk.filter(p => p.sizeBuy > 0).map(p => p.sizeBuy))
    const sellSize = _.sum(
      chunk.filter(p => p.sizeSell > 0).map(p => p.sizeSell),
    )

    const tradeSize = chunk[0].tradeSize

    const buyClassification = Math.min(
      chunkClassification(buySize, tradeSize),
      VISIBLE_MARKERS_THRESHOLD,
    )
    const sellClassification = Math.min(
      chunkClassification(sellSize, tradeSize),
      VISIBLE_MARKERS_THRESHOLD,
    )

    if ((!buyClassification && !sellClassification) || !display) return []
    if (display === "net")
      return [
        buyClassification > sellClassification
          ? [ts, low, buyClassification - sellClassification]
          : [ts, high, buyClassification - sellClassification],
      ] as [number, number, number][]
    else if (display === "all") {
      if (buyClassification && sellClassification)
        return [
          [ts, low, buyClassification],
          [ts, high, -sellClassification],
        ] as [number, number, number][]
      else if (buyClassification && !sellClassification)
        return [[ts, low, buyClassification]] as [number, number, number][]
      else if (!buyClassification && sellClassification)
        return [[ts, high, -sellClassification]] as [number, number, number][]
      else return []
    } else return []
  }

export const computeAvgPriceMarkersPosition =
  (display: "net" | "all") => (chunk: AIActivityData[]) => {
    const ts =
      chunk[0].timestamp + (chunk.at(-1)!.timestamp - chunk[0].timestamp) / 2

    if (display === "net") {
      const totalSizeBuy = _.sum(chunk.map(p => p.sizeBuy)) || 0
      const totalSizeSell = _.sum(chunk.map(p => p.sizeSell)) || 0
      const avgPriceNet =
        _.sum([
          ...chunk.map(p => p.sizeBuy * p.avgPriceBuy),
          ...chunk.map(p => p.sizeSell * p.avgPriceSell),
        ]) /
          (totalSizeBuy + totalSizeSell) || 0

      const classify = totalSizeBuy > totalSizeSell ? "buy" : "sell"
      return [...(avgPriceNet ? [[ts, avgPriceNet, classify]] : [])] as [
        number,
        number,
        "buy" | "sell",
      ][]
    }

    const avgPriceBuy =
      _.sum(chunk.map(p => p.sizeBuy * p.avgPriceBuy)) /
        _.sum(chunk.map(p => p.sizeBuy)) || 0
    const avgPriceSell =
      _.sum(chunk.map(p => p.sizeSell * p.avgPriceSell)) /
        _.sum(chunk.map(p => p.sizeSell)) || 0

    return [
      ...[avgPriceBuy ? [ts, avgPriceBuy, "buy"] : []],
      ...[avgPriceSell ? [ts, avgPriceSell, "sell"] : []],
    ] as [number, number, "buy" | "sell"][]
  }

export const generateAvgPriceMarker =
  (chartAxis: Axis, chunkSizeMinutes: number) =>
  ([x, y, classify]: [number, number, "buy" | "sell"]): AnnotationsOptions => ({
    draggable: "",
    animation: {
      defer: 0,
    },
    shapes: ["_"].map(_ => {
      const shiftX = chunkSizeMinutes * 30 * 1_000
      const shiftXRescaled = RATIO_MARKER_VS_CANDLEWITH * shiftX

      return {
        type: "path",
        points: [
          {
            xAxis: 0,
            yAxis: 0,
            x: x - Math.ceil((1.1 * shiftXRescaled) / 2),
            y: chartAxis.toValue(chartAxis.toPixels(y, true), true),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x + (0.9 * shiftXRescaled) / 2,
            y: chartAxis.toValue(chartAxis.toPixels(y, true), true),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x + (0.9 * shiftXRescaled) / 2,
            y: chartAxis.toValue(chartAxis.toPixels(y, true) + 2, true),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x - Math.ceil((1.1 * shiftXRescaled) / 2),
            y: chartAxis.toValue(chartAxis.toPixels(y, true) + 2, true),
          },
        ],
        fill: getAvgPriceMarkerColor(classify),
        stroke: getAvgPriceMarkerColor(classify),
      }
    }),
  })

export const generateMarker =
  (chartAxis: Axis, chunkSizeMinutes: number) =>
  ([x, y, classify]: [number, number, number]): AnnotationsOptions => ({
    draggable: "",
    animation: {
      defer: 0,
    },
    shapes: ["_"].map(_ => {
      const shiftX = chunkSizeMinutes * 30 * 1_000
      const shiftXRescaled = RATIO_MARKER_VS_CANDLEWITH * shiftX
      const lengthMarker = classify * 4
      const shiftMarkerBase = classify > 0 ? +3 : -3
      const shiftYPeak = classify > 0 ? +6 : -6
      return {
        type: "path",
        points: [
          {
            xAxis: 0,
            yAxis: 0,
            x: x - shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) +
                lengthMarker +
                shiftMarkerBase +
                shiftYPeak,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x + shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) +
                lengthMarker +
                shiftMarkerBase +
                shiftYPeak,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x + shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) + shiftYPeak + shiftMarkerBase,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) + shiftMarkerBase,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x - shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) + shiftMarkerBase + shiftYPeak,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x - shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) +
                lengthMarker +
                shiftMarkerBase +
                shiftYPeak,
              true,
            ),
          },
        ],
        fill: colors.iUltraDarkGrey,
        stroke: classify > 0 ? colors.iGreen : colors.iRedText,
      }
    }),
  })
