import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import { Input } from "antd"
import axios, { AxiosError } from "axios"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AuthContext } from "../../components/auth-context"
import { LoginRegistrationError } from "../../types"
import {
  AlertStyled,
  ButtonStyled,
  ForgottenPasswordLink,
  LoginFormWrapper,
  LoginWrapper,
  TitleLoginForm,
} from "./styled"

const applicationId = process.env.REACT_APP_LOGIN_APP_ID || ""
const url = process.env.REACT_APP_LOGIN_ENDPOINT || ""

const UserLogin = () => {
  const { getCurrentUser, setUser } = useContext(AuthContext)

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const router = useNavigate()
  const location = useLocation()

  useEffect(() => {
    getCurrentUser().then(user => {
      if (!user.isLogged) return

      router("/")
    })
  }, [getCurrentUser, location.key, router])

  const getAccessTokens = async (email: string, password: string) => {
    const res = await axios
      .post(`${url}/api/login`, {
        loginId: email,
        applicationId,
        password,
      })
      .then(res => {
        if (res.status === 202) {
          setError("The user is not registered.")
          throw new Error("Not registered user.")
        }
        return res
      })
      .catch((err: Error | AxiosError) => {
        if (!axios.isAxiosError(err)) return
        if (err.response?.status === 404) {
          setError("Incorrect email or password.")
          return null
        } else {
          const axiosError = err as AxiosError<LoginRegistrationError>
          const fieldErrors = axiosError.response?.data.fieldErrors
          if (!fieldErrors) {
            throw new Error("Unknown error, please try again.")
          }

          const errorMessage = Object.values(fieldErrors)[0][0].message

          setError(
            errorMessage
              .replace(/\[|\]|user\./g, "")
              .replace("loginId", "email"),
          )
          return
        }
      })
    return res?.data
  }

  const submitCredentials = async (email: string, password: string) => {
    setIsLoading(true)
    setError("")
    await getAccessTokens(email, password)
      .then(res => {
        if (!res) {
          console.log("Enter credentials again.")
        } else {
          const { token, refreshToken, user } = res
          setUser({ tokens: { token, refreshToken }, user })
        }
      })
      .catch(err => console.error(err))
      .finally(() => setIsLoading(false))
  }

  return (
    <LoginWrapper>
      <LoginFormWrapper>
        <TitleLoginForm>Login</TitleLoginForm>
        {error ? (
          <AlertStyled message="" description={error} type="error" showIcon />
        ) : null}
        <Input
          placeholder="Email"
          onChange={e => setEmail(e.target.value)}
          onPressEnter={() => submitCredentials(email, password)}
        />
        <Input.Password
          placeholder="Password"
          iconRender={visible =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onChange={e => setPassword(e.target.value)}
          onPressEnter={() => submitCredentials(email, password)}
        />
        <ForgottenPasswordLink onClick={() => router("/password-recovery")}>
          Forgotten your password?
        </ForgottenPasswordLink>
        <ButtonStyled
          type="primary"
          onClick={() => submitCredentials(email, password)}
          disabled={isLoading}
          loading={isLoading}
        >
          Submit
        </ButtonStyled>
      </LoginFormWrapper>
    </LoginWrapper>
  )
}

export default UserLogin
