import { QuestionCircleOutlined } from "@ant-design/icons"
import { Alert, Popconfirm, Switch } from "antd"
import axios from "axios"
import { useContext, useState } from "react"
import { AuthContext } from "../auth-context"
import {
  ContentWrapper,
  LeftWrapper,
  ResetLabel,
  RightWrapper,
  SettingsButtonStyled,
  TitleSettingsCard,
  TitleSettingsWrapper,
  WrapperSettingsCard,
} from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/settings/reset-positions"

const SettingsResetCard = () => {
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const { getCurrentUser } = useContext(AuthContext)
  const [responseError, setResponseError] = useState<boolean>()
  const [responseSuccess, setResponseSuccess] = useState<boolean>()

  const onConfirmFn = async () => {
    setResponseError(false)
    setResponseSuccess(false)
    const user = await getCurrentUser()
    if (!user.isLogged) return

    await axios
      .post(url, null, {
        headers: {
          Authorization: user.tokens.token,
        },
      })
      .then(res => {
        console.log(res.data)
        setResponseSuccess(true)
      })
      .catch(err => {
        console.error(err)
        setResponseError(true)
      })
  }

  return (
    <WrapperSettingsCard>
      <div className="immovable">
        <TitleSettingsWrapper>
          <TitleSettingsCard>Reset Open Positions</TitleSettingsCard>
        </TitleSettingsWrapper>
        <ContentWrapper>
          <LeftWrapper>
            <ResetLabel>Activate reset</ResetLabel>
            <Switch
              value={!buttonDisabled}
              style={{ width: 25 }}
              onChange={() => {
                setButtonDisabled(val => !val)
              }}
            />
          </LeftWrapper>
          <RightWrapper>
            <Popconfirm
              title="Reset positions"
              description="Are you sure you want to reset?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={onConfirmFn}
              onCancel={() => {
                setButtonDisabled(true)
              }}
            >
              <SettingsButtonStyled
                type="primary"
                disabled={buttonDisabled}
                danger
              >
                Reset
              </SettingsButtonStyled>
            </Popconfirm>
          </RightWrapper>
        </ContentWrapper>
        {responseSuccess ? (
          <Alert message="Position successfully reset" type="success" />
        ) : null}
        {responseError ? (
          <Alert message="Unfortunately an error occurred" type="error" />
        ) : null}
      </div>
    </WrapperSettingsCard>
  )
}

export default SettingsResetCard
