import { ClipboardPlus } from "@styled-icons/bootstrap/ClipboardPlus"
import { RequestQuote } from "@styled-icons/material-twotone/RequestQuote"
import { Button, Table } from "antd"
import styled from "styled-components"
import { Search } from "styled-icons/bootstrap"
import * as T from "../../components/typography"
import { colors } from "../../styles"

export const OrderBlotterWrapper = styled.div`
  margin: 0 40px;
`

export const OrderBlotterTitle = styled(T.H2)`
  margin: 15px 0;
`

export const StyledTable = styled(Table)`
  .ant-table {
    overflow-x: auto;
    .ant-dropdown-trigger.ant-table-filter-trigger {
      color: ${colors.iUltraLightGrey};
      :focus {
        background-color: ${colors.iMediumGrey};
      }
      :active {
        background-color: ${colors.iMediumGrey};
      }
      :hover {
        color: ${colors.iMediumGrey};
      }
    }
    .ant-dropdown-trigger.ant-table-filter-trigger.ant-dropdown-open {
      background-color: ${colors.iMediumGrey}b1;
    }
  }

  .ant-table-thead .ant-table-cell {
    background: ${colors.iUltraDarkGrey};
    color: ${colors.iWhite};
  }

  .ant-table-cell {
    background: ${colors.iBlack};
    border-color: ${colors.iUltraDarkGrey};
    color: ${colors.iWhite};
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${colors.iBlack};
  }

  .ant-pagination * {
    background: ${colors.iPrimaryBlue};
    border-color: ${colors.iPrimaryBlue};
    color: ${colors.iWhite};
  }

  .ant-select-selector {
    background: ${colors.iPrimaryBlue} !important;
    border-color: ${colors.iPrimaryBlue} !important;
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: ${colors.iUltraDarkGrey};
  }

  .ant-empty-normal {
    color: ${colors.iWhite};
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${colors.iUltraDarkGrey};
    border-left: 1px solid ${colors.iUltraDarkGrey};
    border-right: 1px solid ${colors.iUltraDarkGrey};
  }

  .ant-table-filter-dropdown {
    background-color: ${colors.iBlack};
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    .ant-checkbox-wrapper + span {
      color: ${colors.iWhite};
    }
    & > * > .ant-input {
      background-color: ${colors.iDarkGrey};
      color: ${colors.iWhite};
      :active {
        border-color: ${colors.iPrimaryBlue};
      }
      :hover {
        border-color: ${colors.iPrimaryBlue};
      }
      :focus {
        border-color: ${colors.iPrimaryBlue};
      }
    }

    & > * > .ant-input::placeholder {
      color: ${colors.iUltraLightGrey};
    }

    & > * > .ant-space {
      display: flex;
      justify-content: space-between;
    }

    & > * > .ant-btn-link[disabled] {
      color: ${colors.iDarkGrey};
    }

    & > * > .ant-input-affix-wrapper {
      background-color: ${colors.iDarkGrey};
      input.ant-input {
        background-color: ${colors.iDarkGrey};
        color: ${colors.iWhite};
      }
    }

    & > .ant-table-filter-dropdown-search {
      * > .ant-input-prefix > .anticon.anticon-search {
        color: ${colors.iMediumGrey};
      }
      border-bottom: 1px solid ${colors.iDarkGrey};
    }

    & > .ant-dropdown-menu {
      background-color: inherit;
      .ant-dropdown-menu-item {
        color: ${colors.iWhite} !important;
        & > * > * > * > .ant-checkbox-inner {
          background-color: ${colors.iBlack};
          border: 1px solid ${colors.iMediumGrey};
        }
        & > * > * > .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: ${colors.iPrimaryBlue};
          }
        }
      }

      .ant-dropdown-menu-item-selected {
        background-color: ${colors.iUltraDarkGrey};
      }

      .ant-dropdown-menu-item:hover {
        background-color: ${colors.iDarkGrey};
      }
    }

    & > .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
      color: ${colors.iWhite};
    }
  }

  .ant-table-filter-dropdown-btns {
    border-top: 1px solid ${colors.iDarkGrey};
    .ant-btn-primary {
      background-color: ${colors.iPrimaryBlue};
      border-color: ${colors.iPrimaryBlue};
    }
  }
` as typeof Table

export const SearchIcon = styled(Search)<{ filtered?: boolean }>`
  width: 13px;
  color: ${({ filtered }) => (filtered ? colors.iPrimaryBlue : "#bfbfbf")};
`

export const BuySellText = styled(T.P3)<{ operation: "Buy" | "Sell" }>`
  color: ${({ operation }) =>
    operation === "Buy" ? colors.iGreen : colors.iRed};
  :after {
    content: "${({ operation }) => operation}";
  }
`

export const SizeText = styled(T.P3)<{ operation: "Buy" | "Sell" }>`
  color: ${({ operation }) =>
    operation === "Buy" ? colors.iGreen : colors.iRed};
`

export const HeaderButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
`

export const StyledButton = styled(Button)`
  & > .btn-label {
    padding-left: 4px;
  }
  ${({ type }) =>
    type === "primary"
      ? `
      , :focus {
        background-color: ${colors.iPrimaryBlue};
        border-color: ${colors.iPrimaryBlue};  
      }
      :hover {
      background-color: ${colors.iBlue04};
      border-color: ${colors.iBlue04};  
    }
    `
      : `
    :hover, :focus {
      color: ${colors.iPrimaryBlue};
      border-color: ${colors.iPrimaryBlue};  
    }
    `}
`

export const StyledPauseButton = styled(Button)<{ paused: string }>`
  & > .btn-label {
    padding-left: 4px;
  }
  ${({ paused }) =>
    paused === "true"
      ? `
      , :focus {
        background-color: ${colors.iRed};
        border-color: ${colors.iRed};  
      }
      :hover {
      background-color: ${colors.iRedText};
      border-color: ${colors.iRedText};  
    }
    `
      : `
      , :focus {
        background-color: ${colors.iPrimaryBlue};
        border-color: ${colors.iPrimaryBlue};  
      }
      :hover {
      background-color: ${colors.iBlue04};
      border-color: ${colors.iBlue04};  
    }
    `}
`

export const ExtendedId = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const CopyIconStyled = styled(ClipboardPlus)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding-right: 5px;
`

export const QuoteIconStyled = styled(RequestQuote)`
  width: 25px;
  height: 25px;
  cursor: pointer;
  padding-right: 5px;
`
