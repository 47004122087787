const DB_NAME = "fspotterDB"
const DB_OBJECT_STORE_NAME = "fspotterStorage"

let indexDb: Promise<IDBDatabase> | undefined = undefined

const init = async () => {
  const openDbRequest = window.indexedDB.open(DB_NAME, 2)

  openDbRequest.onupgradeneeded = (e: any) => {
    const db = e.target.result
    if (e.target.result.objectStoreNames.contains(DB_OBJECT_STORE_NAME))
      db.deleteObjectStore(DB_OBJECT_STORE_NAME)

    db.createObjectStore(DB_OBJECT_STORE_NAME, {})
  }

  return new Promise<IDBDatabase>((resolve, reject) => {
    openDbRequest.onsuccess = (e: any) => {
      if (!e.target.result.objectStoreNames.contains(DB_OBJECT_STORE_NAME))
        e.target.result.createObjectStore(DB_OBJECT_STORE_NAME, {})

      resolve(e.target.result)
    }
    openDbRequest.onerror = _ => {
      const deleteRequest = window.indexedDB.deleteDatabase(DB_NAME)
      deleteRequest.onsuccess = () => init()
      deleteRequest.onerror = reject
    }
  })
}

const put = async <T>(key: string, value: T) => {
  if (!indexDb) indexDb = init()
  const readyDB = await indexDb

  const writeRequest = readyDB
    .transaction([DB_OBJECT_STORE_NAME], "readwrite")
    .objectStore(DB_OBJECT_STORE_NAME)
    .put(value, key)

  return new Promise((resolve, reject) => {
    writeRequest.onsuccess = (e: any) => resolve(e.target.result)
    writeRequest.onerror = reject
  })
}

const get = async (key: string) => {
  if (!indexDb) indexDb = init()
  const readyDB = await indexDb

  const readRequest = readyDB
    .transaction([DB_OBJECT_STORE_NAME])
    .objectStore(DB_OBJECT_STORE_NAME)
    .get(key)

  return new Promise((resolve, reject) => {
    readRequest.onsuccess = _ => resolve(readRequest.result)
    readRequest.onerror = reject
  })
}

const remove = async (key: string) => {
  if (!indexDb) indexDb = init()
  const readyDB = await indexDb

  const readRequest = readyDB
    .transaction([DB_OBJECT_STORE_NAME], "readwrite")
    .objectStore(DB_OBJECT_STORE_NAME)
    .delete(key)

  return new Promise((resolve, reject) => {
    readRequest.onsuccess = _ => resolve(readRequest.result)
    readRequest.onerror = reject
  })
}

const indexedDb = {
  get,
  put,
  remove,
}

export default indexedDb
