import { colors } from "../../styles"

export const colorbarsMapping = (index: number) => {
  switch (index) {
    case 0:
      return `${colors.iOrange}`
    case 1:
      return `${colors.iPurple08}`
    case 2:
      return `${colors.iPurple07}`
    case 3:
      return `${colors.iPurple06}`
    case 4:
      return `${colors.iPurple05}`
    case 5:
      return `${colors.iPurple}`
    case 6:
      return `${colors.iPurple04}`
    case 7:
      return `${colors.iPurple03}`
    case 8:
      return `${colors.iPurple02}`
    default:
      return `${colors.iWhite}`
  }
}
