import { Dropdown, MenuProps } from "antd"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { graphsMenuItems, graphsWithCache } from "../../constants"
import { isBrokerValid } from "../../helpers"
import indexedDb from "../../helpers/indexDB"
import { colors, GraphIcon } from "../../styles"
import { GraphInfo, GraphsList, GraphType } from "../../types"
import CustomTooltip from "../tooltip"
import { deleteGraph, handleMenuClick } from "./helpers"
import {
  CloseIcon,
  DotsIcon,
  GraphHeaderLeft,
  GraphHeaderRight,
  GraphHeaderWrapper,
  StyledDropdownDiv,
  StyledMenuItemText,
  WidgetTitle,
} from "./styled"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
const WidgetHeader: React.FC<{
  graphs: GraphsList
  setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>
  graphInfo: GraphInfo
  showGraphsButton?: boolean
  setShowContributors?: React.Dispatch<React.SetStateAction<boolean>>
  showContributors?: boolean
  widgetTitle: string
}> = ({
  graphs,
  setGraphs,
  graphInfo,
  setShowContributors,
  showContributors,
  widgetTitle,
}) => {
  const { isPairValid } = useContext(PairsBrokersListContext)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dotsRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClick = (event: MouseEvent | TouchEvent) => {
      const { target } = event

      if (!(target instanceof Element) || !dotsRef.current) {
        return
      }
      if (dotsRef.current.contains(target)) return
      setDropdownOpen(false)
    }
    window.addEventListener("click", handleClick)
    return () => {
      window.removeEventListener("click", handleClick)
    }
  }, [])

  const filteredMenuItems = graphsMenuItems.filter(menuItem => {
    return (
      isBrokerValid(graphInfo.broker, menuItem.options) &&
      isPairValid(graphInfo.pair, menuItem.options) &&
      menuItem.graph !== graphInfo.type
    )
  })

  const tooManyGraphsOfSameType = useMemo(
    () =>
      graphsMenuItems.reduce(
        (acc, item) => ({
          ...acc,
          [item.graph]: item.maxItems
            ? graphs.filter(g => g.type === item.graph).length >= item.maxItems
            : false,
        }),
        {},
      ) as Record<GraphType, boolean>,
    [graphs],
  )

  const menuProps: MenuProps = {
    onClick: handleMenuClick(
      graphs,
      setGraphs,
      graphInfo,
      setDropdownOpen,
      setShowContributors,
    ),
    theme: "dark",
    items: [
      graphInfo.type === "liquidity" && graphInfo.broker.id === "aggregated"
        ? {
            label: (
              <StyledMenuItemText>
                {showContributors ? "Hide contributors" : "Show contributors"}
              </StyledMenuItemText>
            ),
            key: "show-contributors",
          }
        : null,
      {
        label: (
          <CustomTooltip
            show={tooManyGraphsOfSameType?.[graphInfo.type]}
            placement={"right"}
            graphType={graphInfo.type}
          >
            <StyledMenuItemText>Duplicate</StyledMenuItemText>
          </CustomTooltip>
        ),
        key: "duplicate",
        disabled: tooManyGraphsOfSameType?.[graphInfo.type],
      },
      {
        label: <StyledMenuItemText>Remove</StyledMenuItemText>,
        key: "remove",
      },
      {
        type: "divider",
      },
      ...(graphsWithCache.includes(graphInfo.type)
        ? [
            {
              label: (
                <CustomTooltip
                  show={true}
                  placement={"right"}
                  title={
                    "Clear the cache for this graph. Then, please refresh the page or duplicate it."
                  }
                  color={colors.iBlue00}
                >
                  <StyledMenuItemText>Clear Cache</StyledMenuItemText>
                </CustomTooltip>
              ),
              key: "show-contributors",
              onClick: () =>
                indexedDb.remove(
                  `${graphInfo.type}-${graphInfo.broker.id}-${graphInfo.pair}`,
                ),
            },
            {
              type: "divider" as "divider",
            },
          ]
        : []),
      ...filteredMenuItems.map(gmi => ({
        label: (
          <CustomTooltip
            show={tooManyGraphsOfSameType?.[gmi.graph]}
            placement={"right"}
            graphType={gmi.graph}
          >
            <StyledMenuItemText>{gmi.label}</StyledMenuItemText>
          </CustomTooltip>
        ),
        key: gmi.graph,
        icon: <GraphIcon icon={gmi?.icon} width={16} />,
        disabled: tooManyGraphsOfSameType?.[gmi.graph],
      })),
    ],
  }

  return (
    <GraphHeaderWrapper>
      <GraphHeaderLeft>
        <Dropdown
          className={"widget-dropdown"}
          menu={menuProps}
          trigger={["click"]}
          dropdownRender={menu => <StyledDropdownDiv>{menu}</StyledDropdownDiv>}
          open={dropdownOpen}
        >
          <div ref={dotsRef}>
            <DotsIcon
              className={"widget-dropdown-icon"}
              onMouseDown={e => {
                setDropdownOpen(oldValue => !oldValue)
              }}
              onTouchStart={() => {
                setDropdownOpen(oldValue => !oldValue)
              }}
            />
          </div>
        </Dropdown>
      </GraphHeaderLeft>
      <WidgetTitle>{widgetTitle}</WidgetTitle>
      <GraphHeaderRight>
        <CloseIcon
          onClick={() => deleteGraph(graphInfo, graphs, setGraphs)}
          onTouchStart={() => deleteGraph(graphInfo, graphs, setGraphs)}
          onMouseDown={e => e.stopPropagation()}
          title={"Close the widget"}
        />
      </GraphHeaderRight>
    </GraphHeaderWrapper>
  )
}

export default WidgetHeader
