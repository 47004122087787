import { pairList } from "../../constants"
import { colors } from "../../styles"
import { TradedPairsDataType } from "../../types"
import CustomTooltip from "../tooltip"
import {
  ExchangeIconStyled,
  HandshakeStyled,
  MagnifyingGlassDollarStyled,
  SwapsIconStyled,
} from "./styled"

const missingTradeConf = ({
  pair,
  tradingConfReceived,
}: TradedPairsDataType[number]) =>
  !tradingConfReceived
    ? `Trading conf message for ${
        pairList.find(({ id }) => id === pair)?.label ?? ""
      } not received`
    : []

const missingTradeVolumeWhileReceivingDeals = ({
  pair,
  tradeSize,
  dealReceived,
}: TradedPairsDataType[number]) =>
  !tradeSize && dealReceived
    ? `Trade volume is missing or null for ${
        pairList.find(({ id }) => id === pair)?.label ?? ""
      } and we are receiving deals for this pair.`
    : []

const missingMasterTradeVolumeWhileReceivingDeals = ({
  pair,
  masterTradeSize,
  dealReceived,
}: TradedPairsDataType[number]) =>
  !masterTradeSize && dealReceived
    ? `Master trade volume is missing or null for ${
        pairList.find(({ id }) => id === pair)?.label ?? ""
      } and we are receiving deals for this pair.`
    : []

const noReceivedQuotes = ({
  pair,
  quoteReceived,
}: TradedPairsDataType[number]) =>
  !quoteReceived
    ? `We are not receiving quotes of ${
        pairList.find(({ id }) => id === pair)?.label ?? ""
      }.`
    : []

const zeroTradeVolume = ({ pair, tradeSize }: TradedPairsDataType[number]) =>
  tradeSize === 0 ? (
    <CustomTooltip
      key={`${pair}-zero-trade-vol`}
      show={true}
      title={`Trade volume for ${
        pairList.find(({ id }) => id === pair)?.label ?? ""
      } is null.`}
      color={colors.iUltraDarkGrey}
      placement={"topLeft"}
      overlayInnerStyle={{
        borderColor: colors.iOrange,
        borderWidth: 3,
        borderStyle: "solid",
        borderRadius: 10,
      }}
    >
      <MagnifyingGlassDollarStyled />
    </CustomTooltip>
  ) : (
    []
  )

const missingStaticExchangeRate = ({
  pair,
  exchangeRate,
}: TradedPairsDataType[number]) =>
  !exchangeRate ? (
    <CustomTooltip
      key={`${pair}-exch-rate-missing`}
      show={true}
      title={`Missing static exchange rate for pair ${
        pairList.find(({ id }) => id === pair)?.label ?? ""
      }. Conversions in PNL might not be correct.`}
      color={colors.iUltraDarkGrey}
      placement={"topLeft"}
      overlayInnerStyle={{
        borderColor: colors.iOrange,
        borderWidth: 3,
        borderStyle: "solid",
        borderRadius: 10,
      }}
    >
      <ExchangeIconStyled width={20} />
    </CustomTooltip>
  ) : (
    []
  )

const missingSwapsData = ({ pair, swaps }: TradedPairsDataType[number]) =>
  !swaps ? (
    <CustomTooltip
      key={`${pair}-no-swap-data`}
      show={true}
      title={`Missing swaps for pair ${
        pairList.find(({ id }) => id === pair)?.label ?? ""
      }.`}
      color={colors.iUltraDarkGrey}
      placement={"topLeft"}
      overlayInnerStyle={{
        borderColor: colors.iOrange,
        borderWidth: 3,
        borderStyle: "solid",
        borderRadius: 10,
      }}
    >
      <SwapsIconStyled width={20} />
    </CustomTooltip>
  ) : (
    []
  )

const zeroMasterTradeVolume = ({
  pair,
  masterTradeSize,
}: TradedPairsDataType[number]) =>
  masterTradeSize === 0 ? (
    <CustomTooltip
      key={`${pair}-zero-master-vol`}
      show={true}
      title={`Master trade volume for ${
        pairList.find(({ id }) => id === pair)?.label ?? ""
      } is null.`}
      color={colors.iUltraDarkGrey}
      placement={"topLeft"}
      overlayInnerStyle={{
        borderColor: colors.iOrange,
        borderWidth: 3,
        borderStyle: "solid",
        borderRadius: 10,
      }}
    >
      <MagnifyingGlassDollarStyled />
    </CustomTooltip>
  ) : (
    []
  )

const noReceivedDeals = ({
  pair,
  dealReceived,
}: TradedPairsDataType[number]) =>
  !dealReceived ? (
    <CustomTooltip
      key={`${pair}-no-deals`}
      show={true}
      title={`We have not received any deal for ${
        pairList.find(({ id }) => id === pair)?.label ?? ""
      }.`}
      color={colors.iUltraDarkGrey}
      placement={"topLeft"}
      overlayInnerStyle={{
        borderColor: colors.iOrange,
        borderWidth: 3,
        borderStyle: "solid",
        borderRadius: 10,
      }}
    >
      <HandshakeStyled width={25} />
    </CustomTooltip>
  ) : (
    []
  )

const warningsFunctions = [
  zeroTradeVolume,
  noReceivedDeals,
  zeroMasterTradeVolume,
  missingStaticExchangeRate,
  missingSwapsData,
]
const errorsFunctions = [
  missingTradeConf,
  missingTradeVolumeWhileReceivingDeals,
  missingMasterTradeVolumeWhileReceivingDeals,
  noReceivedQuotes,
]

export const buildWarnings = (row: TradedPairsDataType[number]) =>
  warningsFunctions.flatMap(f => f(row))

export const buildErrors = (row: TradedPairsDataType[number]): string[] =>
  errorsFunctions.flatMap(f => f(row))

export const writeListElements = (errors: string[]) =>
  errors.map((errorText, idx) => <li key={idx}>{errorText}</li>)
