import { useCallback, useEffect, useMemo, useState } from "react"
import { GraphInfo, GraphReactComponent, WftCostsTick } from "../../types"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { formatCostsWft } from "./helpers"
import { CostRow, RowElement, RowElementBigger, Wrapper } from "./styled"

export const CostsWidget: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [totalCost, setTotalCost] = useState(0)
  const [commission, setCommission] = useState(0)
  const [slippage, setSlippage] = useState(0)
  const [splitting, setSplitting] = useState(0)
  const [swap, setSwap] = useState(0)
  const [quotedSpread, setQuotedSpread] = useState(0)
  //const [totalSpread, setTotalSpread] = useState(0)
  const [dataAvailable, setDataAvailable] = useState(false)

  const wftCostsListener = useCallback((tick: WftCostsTick) => {
    if (tick.brokerPair === "aggregated-all") {
      setDataAvailable(true)
      setTotalCost(tick.data.totalCost)
      setCommission(tick.data.commission)
      setSlippage(tick.data.slippage)
      setSplitting(tick.data.splitting)
      setSwap(tick.data.swap)
      setQuotedSpread(tick.data.quotedSpread)
      //setTotalSpread(tick.data.totalSpread)
    }
  }, [])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "costs",
      id,
      Graph: CostsWidget,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )
  useEffect(() => {
    if (!socket) return () => {}
    socket.on("costs", wftCostsListener)

    return () => socket.off("costs", wftCostsListener)
  }, [socket, wftCostsListener])

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Costs"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <Wrapper>
        <CostRow>
          <RowElementBigger>Total cost</RowElementBigger>
          <RowElementBigger isNegative={totalCost < 0}>
            {dataAvailable ? formatCostsWft(totalCost) : "n/a"}
          </RowElementBigger>
        </CostRow>
        <CostRow>
          <RowElement>Commissions</RowElement>
          <RowElement isNegative={commission < 0}>
            {dataAvailable ? formatCostsWft(commission) : "n/a"}
          </RowElement>
        </CostRow>
        <CostRow>
          <RowElement>Quoted spread</RowElement>
          <RowElement isNegative={quotedSpread < 0}>
            {dataAvailable ? formatCostsWft(quotedSpread) : "n/a"}
          </RowElement>
        </CostRow>
        <CostRow>
          <RowElement>Slippage</RowElement>
          <RowElement isNegative={slippage < 0}>
            {dataAvailable ? formatCostsWft(slippage) : "n/a"}
          </RowElement>
        </CostRow>
        <CostRow>
          <RowElement>Splitting</RowElement>
          <RowElement isNegative={splitting < 0}>
            {dataAvailable ? formatCostsWft(splitting) : "n/a"}
          </RowElement>
        </CostRow>
        <CostRow>
          <RowElement>Swap</RowElement>
          <RowElement isNegative={swap < 0}>
            {dataAvailable ? formatCostsWft(swap) : "n/a"}
          </RowElement>
        </CostRow>
      </Wrapper>
    </WidgetWrapper>
  )
}
