import styled from "styled-components"
import { breakpoint, colors } from "../../styles"
import * as T from "../typography"

export const RowWithBar = styled.div`
  display: grid;
  grid-template-columns: 80px 17fr 6fr;
  grid-column-gap: 6px;
  @media (max-width: ${breakpoint.tablet}) {
    grid-template-columns: 60px 22fr 6fr;
    grid-column-gap: 4px;
  }
`

export const RowWithBarLabel = styled(T.P3)`
  text-align: right;
`

export const Value = styled(T.P3)`
  text-align: right;
  align-self: center;
`

export const HeaderWrapper = styled.div`
  padding-top: 6px;
`

export const HeaderUpdatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
`

export const HeaderUpdatesLabel = styled(T.P4)``
export const HeaderUpdatesValue = styled(T.H6)`
  padding-left: 3px;
`

export const RowWithBarsWrapper = styled.div`
  padding-top: 6px;
  border-top-style: solid;
  border-top-width: 0.5px;
  border-top-color: ${colors.iMediumGrey};
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex-grow: 1;
  row-gap: 10px;
`

export const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 11px;
`

export const BarBackground = styled.div`
  width: 100%;
  background-color: ${colors.iDarkGrey};
  border-radius: 4px;
  height: 8px;
  z-index: 1;
`

interface ValueProps {
  value: number
  backgroundColor?: string
}

// syntax to avoid recreating css attrs at every rerenders
export const BarValue = styled.div.attrs<ValueProps>(props => ({
  style: {
    width: `${props.value}%`,
    backgroundColor: props.backgroundColor
      ? props.backgroundColor
      : `${colors.iWhite}`,
  },
}))<ValueProps>`
  border-radius: 4px;
  height: 8px;
  z-index: 2;
`

export const SpinnerWrapper = styled.div`
  flex-flow: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
