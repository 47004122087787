import axios from "axios"
import RGL from "react-grid-layout"
import { GraphsList, ReactSetter, SavedLayoutsType } from "../../types"

export const createSaveLocalNewLayout = (
  name: string,
  graphs: GraphsList,
  layouts: RGL.Layout[],
  updateSavedLayouts: ReactSetter<SavedLayoutsType>,
) => {
  updateSavedLayouts(oldLayouts => [
    ...(oldLayouts || []),
    {
      name,
      graphs,
      layouts,
    },
  ])
}

export const createSaveRemoteNewLayout = (
  userToken: string,
  url: string,
  newLayout: SavedLayoutsType[number],
) =>
  axios.post(
    url,
    {
      newLayout,
    },
    {
      params: {
        userToken,
      },
      headers: {
        Authorization: userToken,
      },
    },
  )
