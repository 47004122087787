import styled from "styled-components"
import { colors } from "../../styles"

export const WidgetWrapperStyled = styled.div`
  background-color: ${colors.iUltraDarkGrey};
  padding: 0 8px 8px;
  border-radius: 4px;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 2;
`
