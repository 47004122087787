import { Tooltip } from "antd"
import { TooltipPlacement } from "antd/es/tooltip"
import { graphsMenuItems } from "../../constants"
import { colors } from "../../styles"

type TriggerOptions =
  | ("hover" | "focus" | "click" | "contextMenu")[]
  | "hover"
  | "focus"
  | "click"
  | "contextMenu"

interface Props {
  children?: React.ReactNode
  show: boolean
  color?: string
  title?: string | React.ReactNode
  placement?: TooltipPlacement
  graphType?: string
  overlayStyle?: React.CSSProperties
  overlayInnerStyle?: React.CSSProperties
  trigger?: TriggerOptions
}

const CustomTooltip: React.FC<Props> = ({ children, ...props }) => {
  const maxAllowed =
    graphsMenuItems.find(item => item.graph === props.graphType)?.maxItems || 0
  const color = props.color ? props.color : colors.iRed
  const graphLabel =
    graphsMenuItems.find(gmi => props.graphType === gmi.graph)?.label || ""
  const title = props.title
    ? props.title
    : `Maximum number of ${graphLabel} graphs reached. (${maxAllowed})`
  const placement = props.placement ? props.placement : "top"

  return props.show ? (
    <Tooltip placement={placement} title={title} color={color} {...props}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  )
}

export default CustomTooltip
