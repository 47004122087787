import HighchartsReact from "highcharts-react-official"
import * as Highcharts from "highcharts/highstock"
import HighchartsHeatmap from "highcharts/modules/heatmap"
import { colors } from "../../styles"

import { MAX_QUOTES_RATE_MS } from "../../constants"
import { DelayDataType } from "../../types"

HighchartsHeatmap(Highcharts)

type QuoteDelaysMemoizedGraphProps = {
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>
  delaysData: DelayDataType
}

const QuoteDelaysMemoizedGraph = ({
  chartComponentRef,
  delaysData,
}: QuoteDelaysMemoizedGraphProps) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      accessibility: {
        enabled: false,
      },
      chart: {
        backgroundColor: colors.iUltraDarkGrey,
      },
      title: {
        floating: false,
        text: "",
      },
      series: [
        {
          type: "heatmap",
          data:
            delaysData &&
            delaysData.data.map((p: [x: number, y: number, delay: number]) => ({
              x: p[0],
              y: p[1],
              value: p[2],
              text: `${(p[2] / 1000).toFixed(1)}`,
            })),
          borderWidth: 1,
          borderColor: colors.iDarkGrey,
        },
      ],
      xAxis: {
        categories: delaysData && delaysData.columns,
        labels: {
          style: {
            color: colors.iUltraLightGrey,
          },
        },
        min: 0,
        max: Math.min(delaysData.columns.length - 1, 9),
        scrollbar: {
          enabled: true,
        },
      },
      yAxis: {
        categories: delaysData && delaysData.rows,
        title: null,
        labels: {
          style: {
            color: colors.iUltraLightGrey,
          },
        },
        reversed: true,
      },
      colorAxis: {
        min: 0,
        max: MAX_QUOTES_RATE_MS,
        minColor: colors.iGreen,
        maxColor: colors.iRed,
        reversed: false,
        labels: {
          formatter: function (this: any) {
            if (this.value === undefined) return ""
            return `${this.value / 1_000} s`
          },
          style: {
            color: colors.iUltraLightGrey,
          },
        },
      },
      legend: {
        align: "right",
        layout: "vertical",
        margin: 12,
        verticalAlign: "top",
        y: -20,
        title: {
          text: "time",
          style: {
            color: colors.iUltraLightGrey,
          },
        },
      },
      tooltip: {
        formatter: function (this: any) {
          if (!this || !delaysData) return ""
          const { x, y, value } = this.point.options
          const thePair = delaysData.columns.at(x)
          const theBroker = delaysData.rows.at(y)
          return `Last quote of <b>${thePair}</b> received from <b>${theBroker}</b> was <b>${(
            value / 1000
          ).toFixed(2)}s</b> ago.`
        },
      },
    }}
    ref={chartComponentRef}
    containerProps={{
      className: "chartContainer",
      style: {
        height: "inherit",
        width: "100%",
        paddingTop: 8,
      },
    }}
  />
)

export default QuoteDelaysMemoizedGraph
