import styled from "styled-components"
import { colors } from "./../../styles"

export const Wrapper = styled.div`
  min-width: 100%;
  background-color: ${colors.iBlack};
  min-height: 100vh;
  z-index: -100;
`

export const Main = styled.main``
