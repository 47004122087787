import HighchartsReact from "highcharts-react-official"
import * as Highcharts from "highcharts/highstock"
import React from "react"
import { colors } from "../../styles"

type PEPSingleMemoizedGraphProps = {
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>
  setExtremes: Highcharts.AxisSetExtremesEventCallbackFunction
  precision: number
}
const tooltipPointFormat =
  '<div style="line-height: 12px;white-space: nowrap;color:#e9e9ec;">' +
  '<span style="color: {series.color};">&#11044</span>' +
  '<span style="color:#e9e9ec;"> {series.name}: {point.y}</b></span>' +
  "</div><br />"

const PEPSingleMemoizedGraph = ({
  chartComponentRef,
  setExtremes,
  precision,
}: PEPSingleMemoizedGraphProps) => (
  <HighchartsReact
    highcharts={Highcharts}
    constructorType={"StockChart"}
    options={{
      credits: {
        enable: false,
      },
      accessibility: {
        enabled: false,
      },
      boost: {
        allowForce: true,
        enabled: false,
      },
      chart: {
        backgroundColor: colors.iUltraDarkGrey,
        spacingLeft: 0,
        spacingRight: 0,
      },
      navigator: {
        enabled: true,
        height: 25,
      },
      rangeSelector: {
        adaptToUpdatedData: true,
        enabled: true,
        stickToMax: true,
        floating: false,
        buttonPosition: {
          align: "right",
        },
        inputEnabled: false,
        dropdown: "always",
        buttons: [
          {
            type: "minute",
            count: 5,
            text: "5m",
          },
          {
            type: "minute",
            count: 30,
            text: "30m",
          },
          {
            type: "hour",
            count: 1,
            text: "1h",
          },
          {
            type: "hour",
            count: 6,
            text: "6h",
          },
          {
            type: "hour",
            count: 12,
            text: "12h",
          },
          {
            type: "day",
            count: 1,
            text: "1d",
          },
          {
            type: "all",
            text: "All",
          },
        ],
      },
      scrollbar: {
        enabled: true,
      },

      xAxis: {
        gridLineWidth: 0,
        lineColor: colors.iDarkGrey,
        tickWidth: 0,
        type: "datetime",
        ordinal: false,
        events: {
          afterSetExtremes: setExtremes,
        },
      },

      yAxis: [
        //first serie y axis PRICE
        {
          labels: {
            formatter: function (this: any) {
              if (this.value === undefined) return ""
              return Highcharts.numberFormat(this.value, precision, ".")
            },
          },
          visible: true,
          opposite: false,
          endOnTick: false,
          //linkedTo: 0,
          gridLineColor: colors.iGrey01,
          plotLines: [
            {
              value: 0,
              color: colors.iPrimaryBlue,
              width: 1,
              zIndex: 5,
            },
          ],
        },
        {
          //second serie y axis EXPOSURE
          labels: {
            enabled: false,
            formatter: function (this: any) {
              if (this.value === undefined) return ""
              return (
                "$" +
                Highcharts.numberFormat(this.value / 1_000_000, 3, ".", ",") +
                "m"
              )
            },
          },
          gridLineColor: colors.iDarkGrey,
          visible: true,
          //linkedTo: 1,
          endOnTick: false,
          opposite: true,
        },
        {
          //third serie y axis PNL
          labels: {
            formatter: function (this: any) {
              if (this.value === undefined) return ""
              return "$" + Highcharts.numberFormat(this.value, 0, ".", ",")
            },
          },
          //visible: false,
          //linkedTo: 2,
          opposite: true,
          //endOnTick: false,
          //gridLineColor: colors.iDarkGrey,
          plotLines: [
            {
              //value: 0,
              color: colors.iPurple,
              width: 1,
              zIndex: 5,
            },
          ],
        },
      ],
      plotOptions: {
        series: {
          dataGrouping: {
            enabled: false,
          },
          boostThreshold: 1,
          animation: false,
          enableMouseTracking: true,
          negativeColor: true,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      tooltip: {
        useHtml: true,
        backgroundColor: colors.iBlack,
        borderColor: colors.iBlack,
        headerFormat: '<span style="color:#e9e9ec;"><b>{point.key}</b></span>',
        pointFormat: tooltipPointFormat,
        shared: true,
        xDateFormat: "%a, %b %e, %H:%M",
        split: true,
      },
      series: [
        {
          name: "Price",
          type: "line",
          yAxis: 0,
          color: colors.iPrimaryBlue,
          showInNavigator: true,
          tooltip: {
            pointFormatter: function (this: any) {
              const formattedval = Highcharts.numberFormat(
                this.y,
                precision,
                ".",
                ",",
              )
              return (
                '<div style="line-height: 12px;white-space: nowrap;color:#e9e9ec;">' +
                '<span style="color: ' +
                colors.iPrimaryBlue +
                ';">&#11044</span>' +
                '<span style="color:#e9e9ec;"> Price: ' +
                formattedval +
                "</b></span>" +
                "</div><br />"
              )
            },
          },
        },
        {
          name: "Exposure",
          type: "line",
          yAxis: 1,
          color: colors.iGreen,
          negativeColor: colors.iRed,
          negativeThreshold: 0,
          showInNavigator: true,
          tooltip: {
            pointFormatter: function (this: any) {
              const formattedval =
                "$" +
                Highcharts.numberFormat(this.y / 1_000_000, 3, ".", ",") +
                "m"
              return (
                '<div style="line-height: 12px;white-space: nowrap;color:#e9e9ec;">' +
                '<span style="color: ' +
                (this.y > 0 ? colors.iGreen : colors.iRed) +
                ';">&#11044</span>' +
                '<span style="color:#e9e9ec;">Exposure : <b>' +
                formattedval +
                "</b></span>" +
                "</div><br />"
              )
            },
          },
        },
        {
          name: "PNL",
          type: "line",
          yAxis: 2,
          color: colors.iPurple,
          negativeColor: colors.iPurple,
          showInNavigator: false,
          tooltip: {
            pointFormatter: function (this: any) {
              const formattedval =
                "$" + Highcharts.numberFormat(this.y, 0, ".", ",")
              return (
                '<div style="line-height: 12px;white-space: nowrap;color:#e9e9ec;">' +
                '<span style="color: ' +
                colors.iPurple +
                ';">&#11044</span>' +
                '<span style="color:#e9e9ec;"> PNL: ' +
                formattedval +
                "</b></span>" +
                "</div><br />"
              )
            },
          },
        },
      ],
    }}
    ref={chartComponentRef}
    containerProps={{
      className: "chartContainer",
      style: {
        width: "100%",
        height: "100%",
      },
    }}
  />
)

export default React.memo(PEPSingleMemoizedGraph)
