export const loadState = (id: string) => {
  try {
    const serializedState = localStorage.getItem(id)
    if (serializedState === null) return undefined
    const state = JSON.parse(serializedState)
    return state
  } catch (err) {
    return undefined
  }
}

export const saveState = (id: string, state: any) => {
  const stateTemp = state
  try {
    const serializedState = JSON.stringify(stateTemp)
    localStorage.setItem(id, serializedState)
  } catch {
    // catching errors to not break the browser process
  }
}

export const removeState = (id: string) => {
  localStorage.removeItem(id)
}
