import { useEffect } from "react"
import { Socket } from "socket.io-client"
import * as T from "../../components/typography"

export const Reports = ({ socket }: { socket: Socket | undefined }) => {
  useEffect(() => {
    if (!socket) return () => {}
    return () => console.log("todo unsubscribe from socket rooms ...")
  }, [socket])

  return <T.H1>Reports coming soon</T.H1>
}
