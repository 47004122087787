import { PairId } from "../../types"

export const defaultUnits = [
  {
    value: "pip",
    label: "PIPS",
  } as const,
  { value: "dpm", label: "DPM" } as const,
]

export const getunits = (pair: PairId) => {
  switch (pair) {
    case "71_btcusd":
    case "105_btcusdc":
    case "106_btcusdt":
      return [
        {
          value: "pip",
          label: "BP",
        } as const,
        { value: "dpm", label: "$perBTC" } as const,
      ]

    default:
      return defaultUnits
  }
}

export const getTOBSizePrecision = (pair: PairId) => {
  switch (pair) {
    case "71_btcusd":
    case "105_btcusdc":
    case "106_btcusdt":
      return 2
    default:
      return 0
  }
}
