import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import {
  changeGraphComm,
  getBrokerPair,
  getIntervalLabel,
  getPairFromId,
} from "../../helpers"
import {
  GraphInfo,
  GraphReactComponent,
  TOBDominanceData as TOBDominanceDataType,
  TOBDominanceTick,
} from "../../types"
import { LetterIcon } from "../letter-icon"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import {
  TOBDominanceLabel,
  TOBDominancePercentage,
  TOBDominanceRow,
  TOBDominanceValue,
} from "./styled"

export const TOBDominance: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [TOBDominanceData, setTOBDominanceData] =
    useState<TOBDominanceDataType>()

  const listener = useCallback(
    (tick: TOBDominanceTick) => {
      const brokerPair = getBrokerPair(broker, pair, comm, vwap)
      if (tick.brokerPair === brokerPair)
        setTOBDominanceData({
          intervals: tick.data,
          broker: tick.broker,
          pair: getPairFromId(tick.pair),
        })
    },
    [broker, pair, comm, vwap],
  )

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "TOBDominance",
      id,
      Graph: TOBDominance,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("TOBDominance", listener)

    return () => socket.off("TOBDominance", listener)
  }, [socket, graphs, listener])

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="TOB Dominance"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "toggle",
            value: comm || false,
            onClick: changeGraphComm(graphs, setGraphs, graphInfo),
            Icon: LetterIcon("C"),
            title: comm ? "Remove commission" : "Show commission",
          },
        ]}
      />
      <TOBDominanceRow>
        {TOBDominanceData &&
          TOBDominanceData.intervals.map(({ threshold, dominantBroker }) => (
            <Fragment key={`${threshold}-${graphInfo.id}`}>
              <TOBDominanceLabel>
                {getIntervalLabel(threshold)}
              </TOBDominanceLabel>
              <TOBDominanceValue title={dominantBroker.broker[0]}>
                {dominantBroker.broker[0]}
              </TOBDominanceValue>
              <TOBDominancePercentage>
                {(dominantBroker.percentage * 100).toFixed(0) + "%"}
              </TOBDominancePercentage>
            </Fragment>
          ))}
      </TOBDominanceRow>
    </WidgetWrapper>
  )
}
