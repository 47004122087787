import { deleteGraphById, getGraphFromType } from "../../helpers"
import { GraphInfo, GraphsList, GraphType } from "../../types"
import { v4 as v4uuid } from "uuid"
import { MenuProps } from "antd"
import { graphsWithComm, graphsWithVWAP } from "../../constants"

export const deleteGraph = (
  graphInfo: GraphInfo,
  graphs: GraphsList,
  setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>,
) => {
  const newGraphs = deleteGraphById(graphs, graphInfo.id)
  setGraphs(newGraphs)
}

const copyTheGraph = (
  graphInfo: GraphInfo,
  graphs: GraphsList,
  setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>,
) =>
  setGraphs([
    ...graphs,
    {
      id: v4uuid(),
      Graph: getGraphFromType(graphInfo.type),
      broker: graphInfo.broker,
      pair: graphInfo.pair,
      type: graphInfo.type,
      vwap: graphInfo.vwap,
      comm: graphInfo.comm,
      rangeMax: graphInfo.rangeMax,
      rangeMin: graphInfo.rangeMin,
    },
  ])

export const handleMenuClick =
  (
    graphs: GraphsList,
    setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>,
    graphInfo: GraphInfo,
    setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setShowContributors?: React.Dispatch<React.SetStateAction<boolean>>,
  ): MenuProps["onClick"] =>
  input => {
    setDropdownOpen(prevVal => !prevVal)
    switch (input.key) {
      case "duplicate":
        copyTheGraph(graphInfo, graphs, setGraphs)
        break
      case "remove":
        deleteGraph(graphInfo, graphs, setGraphs)
        break
      case "show-contributors":
        setShowContributors && setShowContributors(oldState => !oldState)
        break
      default:
        const graphType = input.key as GraphType
        setGraphs([
          ...graphs,
          {
            id: v4uuid(),
            Graph: getGraphFromType(graphType),
            broker: graphInfo.broker,
            pair: graphInfo.pair,
            type: graphType,
            vwap: graphsWithVWAP.includes(graphType) ? graphInfo.vwap : false,
            comm: graphsWithComm.includes(graphType) ? graphInfo.comm : false,
          },
        ])
    }
  }
