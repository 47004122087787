import axios, { AxiosError } from "axios"
import React, { useState } from "react"
import { defaultLoggedOutUser } from "../../constants"
import { User, UserData, UserTokens } from "../../types"
import { useLocalStorage } from "../../utils/useLocalStorage"
import { refreshTokens, validateToken } from "./helpers"

export const AuthContext = React.createContext<AuthContextType>(
  {} as AuthContextType,
)

type AuthContextType = {
  setUser: ({ tokens, user }: { tokens: UserTokens; user: UserData }) => void
  getCurrentUser: () => Promise<User>
  logout: () => void
  isLoggedIn: boolean
}

const Auth = ({ children }: { children: React.ReactNode }) => {
  const {
    storage: authTokens,
    updateStorage: updateAuthTokens,
    deleteStorage: deleteAuthTokens,
  } = useLocalStorage<UserTokens | undefined>("auth-token", undefined)

  const {
    storage: userData,
    updateStorage: updateUserData,
    deleteStorage: deleteUserData,
  } = useLocalStorage<UserData | undefined>("user-data", undefined)

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const setUser = ({
    tokens,
    user,
  }: {
    tokens: UserTokens
    user: UserData
  }) => {
    updateAuthTokens(tokens)
    updateUserData(user)
  }
  const logout = () => {
    deleteAuthTokens()
    deleteUserData()
    setIsLoggedIn(false)
  }

  const getCurrentUser = async (): Promise<User> => {
    if (!userData || !authTokens) return defaultLoggedOutUser

    const res = await validateToken(authTokens?.token || "")
      .then(_res => ({
        isLogged: true,
        tokens: {
          token: authTokens.token,
          refreshToken: authTokens.refreshToken,
        },
        user: userData,
      }))
      .catch(async (err: Error | AxiosError) => {
        if (!axios.isAxiosError(err)) return defaultLoggedOutUser

        if (err.response?.status === 401) {
          return await refreshTokens(authTokens?.refreshToken || "")
            .then(newTokens => {
              const { token, refreshToken } = newTokens
              updateAuthTokens({ token, refreshToken })
              return {
                isLogged: true,
                tokens: {
                  token,
                  refreshToken,
                },
                user: userData,
              }
            })
            .catch(_err => defaultLoggedOutUser)
        }

        return defaultLoggedOutUser
      })

    setIsLoggedIn(res.isLogged)

    return res
  }

  return (
    <AuthContext.Provider
      value={{
        setUser,
        getCurrentUser,
        logout,
        isLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default Auth
