export const getIsCurrentPriceUnavailable = (
  current: number,
  pnlPip: number,
  pnlUsd: number,
) =>
  current < 0.002 &&
  current > -0.002 &&
  pnlPip < 0.002 &&
  pnlPip > -0.002 &&
  pnlUsd < 0.002 &&
  pnlUsd > -0.002
