export const labelToPipFormatter =
  (precision: number) => (ctx: Highcharts.AxisLabelsFormatterContextObject) => {
    const label = ctx.axis.defaultLabelFormatter.call(ctx)
    return Number(label).toFixed(precision)
  }

export const zoomButtons = (chunkSizeMinutes: number) => {
  if (chunkSizeMinutes === 1)
    return [
      {
        type: "minute",
        count: 15,
        text: "15m",
      },
      {
        type: "minute",
        count: 30,
        text: "30m",
      },
      {
        type: "hour",
        count: 1,
        text: "1h",
      },
      {
        type: "hour",
        count: 2,
        text: "2h",
      },
      {
        type: "hour",
        count: 3,
        text: "3h",
      },
    ]
  else if (chunkSizeMinutes === 5)
    return [
      {
        type: "hour",
        count: 1,
        text: "1h",
      },
      {
        type: "hour",
        count: 3,
        text: "3h",
      },
      {
        type: "hour",
        count: 6,
        text: "6h",
      },
      {
        type: "hour",
        count: 9,
        text: "9h",
      },
      {
        type: "hour",
        count: 12,
        text: "12h",
      },
    ]
  else if (chunkSizeMinutes === 60)
    return [
      {
        type: "hour",
        count: 6,
        text: "6h",
      },
      {
        type: "hour",
        count: 12,
        text: "12h",
      },
      {
        type: "day",
        count: 1,
        text: "1d",
      },
      {
        type: "day",
        count: 2,
        text: "2d",
      },
      {
        type: "day",
        count: 3,
        text: "3d",
      },
    ]
  else
    return [
      {
        type: "minute",
        count: 30,
        text: "30m",
      },
      {
        type: "hour",
        count: 1,
        text: "1h",
      },
      {
        type: "hour",
        count: 3,
        text: "3h",
      },
      {
        type: "hour",
        count: 6,
        text: "6h",
      },
      {
        type: "hour",
        count: 12,
        text: "12h",
      },
    ]
}

export const getMaxRange = (chunkSizeMinutes: number) => {
  const hour = 3_600 * 1_000
  if (chunkSizeMinutes === 1) return hour * 5
  if (chunkSizeMinutes === 5) return hour * 24
  return hour * 24 * 8
}
