import { Button } from "antd"
import styled from "styled-components"
import { colors } from "../../styles"

const StyledButton = styled(Button)<{ selected?: boolean }>`
  margin: 0 10px 0 0;
  height: 16px !important;
  display: flex;
  align-items: center;
  font-size: 12px !important;
  ${({ selected }) =>
    selected
      ? `
    , :focus {
      background-color: ${colors.iPrimaryBlue};
      border-color: ${colors.iPrimaryBlue};
      color: ${colors.iWhite};
    }
    :hover {
      background-color: ${colors.iBlue04};
      border-color: ${colors.iBlue04};
      color: ${colors.iWhite};

    }
    `
      : `
      color: ${colors.iMediumGrey};
      border-color: ${colors.iDarkGrey};  
      background-color: ${colors.iDarkGrey};

    :hover, :focus {
      color: ${colors.iLightGrey};
      background-color: ${colors.iDarkGrey};
      border-color: ${colors.iDarkGrey};  
    }
    `}
`

export default StyledButton
