import RGL from "react-grid-layout"
import { ReactSetter } from "../../types"

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const handleWindowResize = (
  rglRef: React.RefObject<HTMLDivElement>,
  setCols: ReactSetter<number | undefined>,
  setRglWidth: ReactSetter<number>,
) => {
  function innerHandleResize() {
    const width = rglRef?.current?.clientWidth || getWindowDimensions().width
    const minWidth = 50
    const newCols = Math.floor(width / minWidth)
    setCols(newCols)
    setRglWidth(width - (width % minWidth))
  }

  window.addEventListener("resize", innerHandleResize)
  innerHandleResize()

  return () => {
    window.removeEventListener("resize", innerHandleResize)
  }
}

const findFirstFreeSpot = (
  layouts: RGL.Layout[],
  { w, h }: { w: number; h: number },
  cols: number | undefined,
) => {
  if (cols === undefined) return { x: 0, y: 0 }
  let [x, y] = [0, 0]

  const isFree = (theX: number, theY: number, layouts: RGL.Layout[]) =>
    !layouts.some(existingGraph => {
      const tlExisting = [existingGraph.x, existingGraph.y]
      const brExisting = [
        existingGraph.x + Math.min(existingGraph.w, cols),
        existingGraph.y + existingGraph.h,
      ]
      const tlNew = [theX, theY]
      const brNew = [theX + w, theY + h]

      if (tlExisting[0] >= brNew[0] || tlNew[0] >= brExisting[0]) {
        return false
      }
      if (tlExisting[1] >= brNew[1] || tlNew[1] >= brExisting[1]) {
        return false
      }
      return true
    })

  while (true) {
    if (isFree(x, y, layouts)) {
      return { x, y }
    } else {
      const isOverflowing = x + 1 + w > cols
      if (isOverflowing) {
        y = y + 1
        x = 0
      } else {
        x = x + 1
      }
    }
  }
}

export const computeLayouts = (
  widgets: {
    Widget: JSX.Element
    layout: {
      w: number
      h: number
    }
  }[],
  cols: number | undefined,
) =>
  widgets.reduce(
    (acc, { layout }, i) => [
      ...acc,
      {
        ...layout,
        ...findFirstFreeSpot(acc, layout, cols),
        i: `MonitoringWidget${i}`,
      },
    ],
    [] as RGL.Layout[],
  )
