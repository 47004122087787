const formatter = Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})
export const formatTableWft = (value: number) => formatter.format(value)

const formatterVolume = Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
})
export const formatVolumeWft = (volume: number) =>
  formatterVolume.format(volume / 1_000_000) + "m"

const formatterDeals = Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})
export const formatDealsWft = (value: number) => formatterDeals.format(value)
