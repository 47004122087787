export const labelToPipFormatter =
  (precision: number) => (ctx: Highcharts.AxisLabelsFormatterContextObject) => {
    const label = ctx.axis.defaultLabelFormatter.call(ctx)
    return Number(label) /* 10 ** (precision - 1)*/
      .toFixed(precision + 1)
  }


export const getHorizontalMarkerWidth = (precision: number) => {
  switch (precision) {
    case 3:
    case 2:
    case 1: return "50"
    case 5: return "70"
    default: return "70"
  }
}

export const getHorizontalLabelPadding = (precision: number) => {
  switch (precision) {
    case 3: return "4"
    case 2: return "10"
    case 1: return "15"
    default: return "8"
  }
}