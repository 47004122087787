import { Spin } from "antd"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { getBrokerPair, getPairFromId } from "../../helpers"
import { colors } from "../../styles"
import {
  GraphInfo,
  GraphReactComponent,
  SpreadCounterData,
  SpreadCounterTick,
} from "../../types"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { colorbarsMapping } from "./helpers"
import { SpinnerWrapper, SpreadCounterWrapper } from "./styled"

const SpreadCounter: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [spreadCounterData, setSpreadCounterData] =
    useState<SpreadCounterData>()

  const liquidityListener = useCallback(
    (tick: SpreadCounterTick) => {
      const brokerPair = getBrokerPair(broker, pair)
      if (tick.brokerPair === brokerPair)
        setSpreadCounterData({
          broker: tick.broker,
          spreadCounter: tick.data,
          pair: getPairFromId(tick.pair),
        })
    },
    [broker, pair],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("spreadCounter", liquidityListener)

    return () => socket.off("spreadCounter", liquidityListener)
  }, [socket, graphs, liquidityListener])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "spreadCounter",
      id,
      Graph: SpreadCounter,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Spread Counter"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      {spreadCounterData ? (
        <SpreadCounterWrapper>
          <HighchartsReact
            containerProps={{
              className: "chartContainer",
              style: {
                height: "170px",
                width: "100%",
              },
            }}
            highcharts={Highcharts}
            constructorType={"chart"}
            options={{
              accessibility: {
                enabled: false,
              },
              chart: {
                backgroundColor: colors.iUltraDarkGrey,
                animation: false,
                type: "column",
                spacingRight: -10,
                spacingLeft: 0,
              },
              title: {
                text: "",
              },
              legend: {
                enabled: false,
              },
              xAxis: {
                lineColor: colors.iDarkGrey,
                lineWidth: 1,
                tickWidth: 0,
                categories: ["<0", "0", "1", "2", "3", "4", "5", "6", "7"],
              },
              yAxis: {
                lineWidth: 0,
                tickWidth: 0,
                opposite: true,
                gridLineColor: `${colors.iUltraLightGrey}25`,
                title: {
                  text: "",
                },
                labels: {
                  format: "{value}%",
                },
                min: 0,
                max: 100,
                offset: -10,
              },
              plotOptions: {
                series: {
                  animation: false,
                  borderRadius: 4,
                  borderWidth: 0,
                  pointWidth: 7,
                },
                column: {
                  states: {
                    hover: {
                      enabled: true,
                    },
                  },
                },
              },
              series: {
                type: "column",
                data: Object.entries(spreadCounterData.spreadCounter).map(
                  ([_, percentage], index) => ({
                    y: percentage * 100,
                    color: colorbarsMapping(index),
                  }),
                ),
              },
              tooltip: {
                formatter: function (
                  this: Highcharts.TooltipFormatterContextObject,
                ) {
                  return this.point.y?.toFixed(2) + "%"
                },
                positioner: function (
                  _width: number,
                  _height: number,
                  point: any,
                ) {
                  return {
                    x: point.plotX + 5,
                    y: point.plotY + 25,
                  }
                },
              },
            }}
            ref={chartComponentRef}
          />
        </SpreadCounterWrapper>
      ) : (
        <SpinnerWrapper>
          <Spin />
        </SpinnerWrapper>
      )}
    </WidgetWrapper>
  )
}

export default SpreadCounter
