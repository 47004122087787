import { ManualExposureDataType } from "../../types"

export const changeSignToExposure = (
  val: ManualExposureDataType["exposure"][number],
) => ({
  ...val,
  volume: -1 * val.volume,
})

export const reshapeData = (data: ManualExposureDataType) => ({
  ...data,
  exposure: data.exposure.map(changeSignToExposure),
})
