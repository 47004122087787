import highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { getBrokerPair, getColor } from "../../helpers"
import { colors } from "../../styles"
import {
  CurrencyExposureData,
  CurrencyExposureTick,
  GraphInfo,
  GraphReactComponent,
} from "../../types"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import {
  CurrencyExposureGridElement,
  CurrencyExposureGridHeader,
  CurrencyExposureLeftWrapper,
  CurrencyExposureRightWrapper,
  CurrencyExposureWrapper,
  VerticalLine,
} from "./styled"

highcharts.setOptions({
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
  },
})

const CurrencyExposure: GraphReactComponent = ({
  socket,
  graphs,
  setGraphs,
  broker,
  id,
  vwap,
  comm,
  pair,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const [hoveredPair, setHoveredPair] = useState<string>()
  const [currencyExposure, setCurrencyExposure] =
    useState<CurrencyExposureData>([])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "currencyExposure",
      id,
      Graph: CurrencyExposure,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  useEffect(() => {
    const hoveredPoint =
      chartComponentRef?.current?.chart?.series?.[0]?.data?.find(
        (d: any) => d.key === hoveredPair,
      )
    if (!hoveredPoint) {
      chartComponentRef?.current?.chart?.tooltip?.destroy()
      return
    }

    hoveredPoint?.setState("hover")
    chartComponentRef?.current?.chart?.tooltip?.refresh(hoveredPoint)
  }, [hoveredPair])

  const brokerPair = useMemo(() => getBrokerPair(broker, "all"), [broker])
  const currencyExposureListener = useCallback(
    (tick: CurrencyExposureTick) => {
      if (tick.brokerPair !== brokerPair) return
      setCurrencyExposure(
        tick.data.sort((a, b) => (a.currency > b.currency ? 1 : -1)),
      )
    },
    [brokerPair],
  )
  const { colorsMapper } = useContext(PairsBrokersListContext)

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("currencyExposure", currencyExposureListener)

    return () => socket.off("currencyExposure", currencyExposureListener)
  }, [socket, graphs, currencyExposureListener])

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 10)
  }, [])

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Currency Exposure"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <CurrencyExposureWrapper>
        <CurrencyExposureLeftWrapper>
          <HighchartsReact
            highcharts={highcharts}
            options={{
              accessibility: {
                enabled: false,
              },
              chart: {
                backgroundColor: colors.iUltraDarkGrey,
                spacingLeft: -28,
                spacingRight: -30,
              },
              colors: currencyExposure.map(({ currency }) =>
                getColor(colorsMapper, currency),
              ),
              dataLabels: {
                enabled: false,
              },
              title: {
                text: "",
              },
              plotOptions: {
                pie: {
                  animation: false,
                  point: {
                    events: {
                      mouseOver() {
                        setHoveredPair((this as any).key || "")
                      },
                      mouseOut() {
                        setHoveredPair("")
                      },
                    },
                  },
                  dataLabels: {
                    filter: {
                      property: "percentage",
                      operator: ">",
                      value: 2,
                    },
                  },
                },
              },
              series: [
                {
                  type: "pie",
                  innerSize: "57%",
                  borderColor: "transparent",
                  dataLabels: {
                    enabled: true,
                    format: "{point.percentage:.0f}%",
                    inside: true,
                    distance: "-22.5%",
                    color: colors.iWhite,
                    style: {
                      textOutline: "transparent",
                      fontFamily: "Lato-Regular",
                      fontSize: "10px",
                    },
                  },
                  tooltip: {
                    followPointer: false,
                    headerFormat: "",
                    pointFormat: `<b>{point.key}</b><br/>Exposure USD: {point.amountUSD:,.2f}<br/>Percentage: {point.percentage:.2f}%`,
                  },
                  name: "Exposure USD",
                  data: currencyExposure.map(({ amountUSD, currency }, i) => ({
                    y: Math.abs(amountUSD),
                    amountUSD,
                    key: currency,
                    selected: i === 0,
                  })),
                },
              ],
            }}
            ref={chartComponentRef}
            containerProps={{
              className: "chartContainer",
              style: {
                height: "160px",
                width: "100%",
              },
            }}
          />
        </CurrencyExposureLeftWrapper>
        <VerticalLine />
        <CurrencyExposureRightWrapper>
          {["CURRENCY", "AMOUNT", "RATE", "AMOUNT (USD)"].map(label => (
            <CurrencyExposureGridHeader key={"label" + label}>
              {label}
            </CurrencyExposureGridHeader>
          ))}
          {currencyExposure.map(data => {
            const sharedProps = {
              isOpaque: hoveredPair ? data.currency === hoveredPair : true,
              onMouseOver: () => setHoveredPair(data.currency),
              onTouchStart: () => setHoveredPair(data.currency),
              onMouseLeave: () => setHoveredPair(""),
              onTouchEnd: () => setHoveredPair(""),
            }
            return (
              <React.Fragment key={data.currency}>
                <CurrencyExposureGridElement
                  dot={getColor(colorsMapper, data.currency)}
                  {...sharedProps}
                >
                  {data.currency}
                </CurrencyExposureGridElement>
                <CurrencyExposureGridElement
                  negative={data.amount < 0}
                  {...sharedProps}
                >
                  {data.amount.toLocaleString("en", {
                    style: "currency",
                    currency: data.currency,
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    currencyDisplay: "narrowSymbol",
                  })}
                </CurrencyExposureGridElement>
                <CurrencyExposureGridElement {...sharedProps}>
                  {data.rate.toLocaleString("en", {
                    minimumFractionDigits: data.currency === "JPY" ? 3 : 5,
                    maximumFractionDigits: data.currency === "JPY" ? 3 : 5,
                  })}
                </CurrencyExposureGridElement>
                <CurrencyExposureGridElement {...sharedProps}>
                  {data.amountUSD.toLocaleString("en", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                    currencyDisplay: "narrowSymbol",
                  })}
                </CurrencyExposureGridElement>
              </React.Fragment>
            )
          })}
        </CurrencyExposureRightWrapper>
      </CurrencyExposureWrapper>
    </WidgetWrapper>
  )
}

export default CurrencyExposure
