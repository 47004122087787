import Auth from "./components/auth-context"
import Router from "./router"

function App() {
  return (
    <Auth>
      <Router />
    </Auth>
  )
}

export default App
