import { memo, useEffect, useMemo, useRef } from "react"
import { GraphInfo, GraphReactComponent } from "../../types"
import WidgetHeader from "../widget-header"
import { WidgetWrapper } from "../widget-wrapper"

const EconomicCalendarWidget: GraphReactComponent = ({
  graphs,
  setGraphs,
  broker,
  pair,
  id,
}) => {
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!container.current) return
    const script = document.createElement("script")
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-events.js"
    script.type = "text/javascript"
    script.async = true
    script.innerHTML = `
      {
        "width": "100%",
        "height": "100%",
        "colorTheme": "dark",
        "isTransparent": false,
        "locale": "en",
        "importanceFilter": "0,1",
        "countryFilter": "us,eu,jp"
      }
    `
    container.current.appendChild(script)
  }, [])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "externalEconomicCalendar",
      id,
      Graph: EconomicCalendarWidget,
    }),
    [broker, id, pair],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Economic Calendar"
      />
      <div
        className="tradingview-widget-container"
        ref={container}
        style={{ height: "100%", width: "100%" }}
      >
        <div
          className="tradingview-widget-container__widget"
          style={{ height: "calc(100% - 32px)", width: "100%" }}
        />
      </div>
    </WidgetWrapper>
  )
}

export default memo(EconomicCalendarWidget)
