import { Broker } from "../../types"

export const pluckAggregatedBrokers = (brokersList: Broker[]) => {
  const aggregatedIndex = brokersList.findIndex(({ id }) => id === "aggregated")

  return [
    brokersList[aggregatedIndex],
    [
      ...brokersList.slice(0, aggregatedIndex),
      ...brokersList.slice(aggregatedIndex + 1),
    ],
  ] as const
}
