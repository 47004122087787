import { AnnotationsOptions, Axis } from "highcharts"
import _ from "lodash"
import { colors } from "../../styles"
import { AIActivityData } from "../../types"

const VISIBLE_MARKERS_THRESHOLD = 12
const RATIO_MARKER_VS_CANDLEWITH = 1.4

const chunkClassification = (deltaSize: number, tradeSize: number) =>
  _.toInteger(deltaSize / tradeSize)

export const computeMarkersPosition =
  (display: "net" | "all") =>
  (chunk: AIActivityData[]): [number, number, number][] => {
    const ts =
      chunk[0].timestamp + (chunk.at(-1)!.timestamp - chunk[0].timestamp) / 2

    const buySize = _.sum(chunk.filter(p => p.sizeBuy > 0).map(p => p.sizeBuy))
    const sellSize = _.sum(
      chunk.filter(p => p.sizeSell > 0).map(p => p.sizeSell),
    )

    const avgPriceBuy =
      _.sum(chunk.map(c => c.avgPriceBuy * c.sizeBuy)) /
        _.sum(chunk.map(p => p.sizeBuy)) || 0
    const avgPriceSell =
      _.sum(chunk.map(c => c.avgPriceSell * c.sizeSell)) /
        _.sum(chunk.map(p => p.sizeSell)) || 0
    const avgPrice =
      (avgPriceBuy * buySize + avgPriceSell * sellSize) / (buySize + sellSize)

    const tradeSize = chunk[0].tradeSize

    const buyClassification = Math.min(
      chunkClassification(buySize, tradeSize),
      VISIBLE_MARKERS_THRESHOLD,
    )
    const sellClassification = Math.min(
      chunkClassification(sellSize, tradeSize),
      VISIBLE_MARKERS_THRESHOLD,
    )

    if ((!buyClassification && !sellClassification) || !display) return []
    if (display === "net")
      return [[ts, avgPrice, buyClassification - sellClassification]]
    else if (display === "all") {
      if (buyClassification && sellClassification)
        return [
          [ts, avgPriceBuy, buyClassification],
          [ts, avgPriceSell, -sellClassification],
        ]
      else if (buyClassification && !sellClassification)
        return [[ts, avgPriceBuy, buyClassification]]
      else if (!buyClassification && sellClassification)
        return [[ts, avgPriceSell, -sellClassification]]
      else return []
    } else return []
  }

export const generateMarker =
  (chartAxis: Axis, chunkSizeMinutes: number) =>
  ([x, y, classify]: [number, number, number]): AnnotationsOptions => ({
    draggable: "",
    animation: {
      defer: 0,
    },
    shapes: ["_"].map(_ => {
      const shiftX = chunkSizeMinutes * 30 * 1_000
      const shiftXRescaled = RATIO_MARKER_VS_CANDLEWITH * shiftX
      const lengthMarker = classify * 4
      const shiftMarkerBase = classify > 0 ? +3 : -3
      const shiftYPeak = classify > 0 ? +6 : -6
      return {
        type: "path",
        points: [
          {
            xAxis: 0,
            yAxis: 0,
            x: x - shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) +
                lengthMarker +
                shiftMarkerBase +
                shiftYPeak,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x + shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) +
                lengthMarker +
                shiftMarkerBase +
                shiftYPeak,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x + shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) + shiftYPeak + shiftMarkerBase,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) + shiftMarkerBase,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x - shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) + shiftMarkerBase + shiftYPeak,
              true,
            ),
          },
          {
            xAxis: 0,
            yAxis: 0,
            x: x - shiftXRescaled / 2,
            y: chartAxis.toValue(
              chartAxis.toPixels(y, true) +
                lengthMarker +
                shiftMarkerBase +
                shiftYPeak,
              true,
            ),
          },
        ],
        fill: "transparent",
        stroke: classify > 0 ? colors.iGreenText : colors.iRedText,
        strokeWidth: 1,
      }
    }),
  })
