import { Spin } from "antd"
import HighchartsReact from "highcharts-react-official"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { UserAddOutline } from "styled-icons/typicons"
import { pairList } from "../../constants"
import { getBrokerPair } from "../../helpers"
import {
  DelayDataType,
  GraphInfo,
  GraphReactComponent,
  PairId,
  QuotesDelaysData,
  QuotesDelaysTick,
} from "../../types"
import QuoteDelaysMemoizedGraph from "../quotes-delays-memoized-graph"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { reshapeAndFilterBrokers, reshapeData } from "./helpers"
import { SpinnerWrapper } from "./styled"

const QuotesDelays: GraphReactComponent = ({
  graphs,
  setGraphs,
  socket,
  broker,
  pair,
  id,
  vwap,
  comm,
}) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const brokerPair = useMemo(() => getBrokerPair(broker, pair), [broker, pair])
  const { brokersList } = useContext(PairsBrokersListContext)

  const [delaysData, setDelaysData] = useState<DelayDataType>()
  const [showAllBrokers, setShowAllBrokers] = useState<boolean>(true)
  const [tickBuffer, setTickBuffer] = useState<QuotesDelaysData>()

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 0)
  }, [])

  useEffect(() => {
    if (!tickBuffer) return
    const colsLabels = tickBuffer.colsPairs.map(
      (p: PairId) => pairList.find(({ id }) => id === p)?.label || "",
    )

    const parsedData = showAllBrokers
      ? reshapeData(tickBuffer, colsLabels)
      : reshapeAndFilterBrokers(tickBuffer, brokersList, colsLabels)

    setDelaysData(parsedData)
  }, [tickBuffer, showAllBrokers, brokersList])

  const quotesDelaysListener = useCallback(
    (tick: QuotesDelaysTick) => {
      if (tick.brokerPair !== brokerPair) return
      setTickBuffer(tick.data)
    },
    [brokerPair],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("quotesDelays", quotesDelaysListener)

    return () => socket.off("quotesDelays", quotesDelaysListener)
  }, [socket, quotesDelaysListener])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "quotesDelays",
      id,
      Graph: QuotesDelays,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Quotes Freshness"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        options={[
          {
            type: "toggle",
            value: showAllBrokers,
            onClick: () => setShowAllBrokers(oldState => !oldState),
            Icon: UserAddOutline,
            title: showAllBrokers
              ? "Show only used brokers"
              : "Display all brokers",
          },
        ]}
      />
      {delaysData ? (
        <div className="immovable">
          <QuoteDelaysMemoizedGraph
            delaysData={delaysData}
            chartComponentRef={chartComponentRef}
          />
        </div>
      ) : (
        <SpinnerWrapper>
          <Spin />
        </SpinnerWrapper>
      )}
    </WidgetWrapper>
  )
}

export default QuotesDelays
