import { Input } from "antd"
import axios from "axios"
import { useCallback, useContext, useMemo, useState } from "react"
import RGL from "react-grid-layout"
import { z } from "zod"
import { GraphIcon as ModalIcon, SaveStyled } from "../../styles"
import { GraphsList, ReactSetter, SavedLayoutsType } from "../../types"
import { AuthContext } from "../auth-context"
import { createSaveLocalNewLayout, createSaveRemoteNewLayout } from "./helpers"
import {
  CloseStyled,
  InputSupTitle,
  SaveLayoutModalContent,
  SaveLayoutModalFooter,
  SaveLayoutModalHeader,
  SaveLayoutModalHeaderLeft,
  SaveLayoutModalHeaderTitle,
  SaveLayoutModalWrapper,
  StyledButton,
  StyledAlert,
} from "./styled"

const SaveDashboardLayoutModal: React.FC<{
  updateSavedLayouts: ReactSetter<SavedLayoutsType>
  setOpenModalSaveLayout: React.Dispatch<React.SetStateAction<boolean>>
  graphs: GraphsList
  layouts: RGL.Layout[]
  setCurrentLayoutName: React.Dispatch<React.SetStateAction<string>>
  setIsLayoutChanged: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  setOpenModalSaveLayout,
  graphs,
  layouts,
  updateSavedLayouts,
  setCurrentLayoutName,
  setIsLayoutChanged,
}) => {
  const [inputName, setInputName] = useState<string>("")
  const [error, setError] = useState<string>("")
  const { getCurrentUser } = useContext(AuthContext)
  const url = useMemo(
    () =>
      (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
      "/liquidity-dashboard/layouts",
    [],
  )

  const submit = useCallback(async () => {
    const user = await getCurrentUser()
    if (!user.isLogged) return
    const newLayout = {
      name: inputName,
      graphs,
      layouts,
    }
    createSaveRemoteNewLayout(user.tokens.token, url, newLayout)
      .then(_ => {
        createSaveLocalNewLayout(inputName, graphs, layouts, updateSavedLayouts)
        setOpenModalSaveLayout(false)
        setCurrentLayoutName(inputName)
        setIsLayoutChanged(false)
      })
      .catch(e => {
        console.error(e)
        const message = z.string().safeParse(e?.response?.data)
        if (axios.isAxiosError(e) && message.success) {
          setError(message.data)
        } else {
          setError("Unkown error occurred, please try again.")
        }
      })
  }, [
    getCurrentUser,
    url,
    graphs,
    inputName,
    layouts,
    setCurrentLayoutName,
    setIsLayoutChanged,
    setOpenModalSaveLayout,
    updateSavedLayouts,
  ])

  return (
    <SaveLayoutModalWrapper>
      <SaveLayoutModalHeader>
        <SaveLayoutModalHeaderLeft>
          <ModalIcon icon={SaveStyled} />
          <SaveLayoutModalHeaderTitle>
            Save Dashboard
          </SaveLayoutModalHeaderTitle>
        </SaveLayoutModalHeaderLeft>
        <CloseStyled onClick={() => setOpenModalSaveLayout(false)} />
      </SaveLayoutModalHeader>
      <SaveLayoutModalContent>
        <InputSupTitle>Assign a name to this configuration</InputSupTitle>
        <Input
          onChange={e => setInputName(e.target.value)}
          value={inputName}
          placeholder="Name"
          onPressEnter={submit}
        />
        {error && (
          <StyledAlert message="" description={error} type="error" showIcon />
        )}
      </SaveLayoutModalContent>
      <SaveLayoutModalFooter>
        <StyledButton onClick={() => setOpenModalSaveLayout(false)}>
          Cancel
        </StyledButton>
        <StyledButton type="primary" onClick={submit}>
          Save Dashboard
        </StyledButton>
      </SaveLayoutModalFooter>
    </SaveLayoutModalWrapper>
  )
}

export default SaveDashboardLayoutModal
