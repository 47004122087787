import styled from "styled-components"
import { colors } from "../../styles"
import * as T from "../typography"

export const Wrapper = styled.div`
  padding-top: 8px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`

export const CostRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RowElement = styled(T.P2)<{
  isNegative?: boolean
}>`
  color: ${props => (props.isNegative ? colors.iRed : colors.iWhite)};
`

export const RowElementBigger = styled(T.H3)<{
  isNegative?: boolean
}>`
  color: ${props => (props.isNegative ? colors.iRed : colors.iWhite)};
  padding-bottom: 12px;
`
