import styled from "styled-components"
import { colors, breakpointNum } from "../../styles"
import { ReactComponent as Logo } from "../../icons/logo.svg"
import { LogoutCircleR } from "@styled-icons/remix-line/LogoutCircleR/"
import { Cog } from "@styled-icons/boxicons-regular/Cog"
import { Menu } from "@styled-icons/boxicons-regular/Menu"
import { CloseOutline } from "@styled-icons/evaicons-outline/CloseOutline"

import * as T from "./../typography"

export const WrapperHeader = styled.header`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.iUltraDarkGrey};
  padding: 0 40px;
`

export const RightMenu = styled.div`
  height: 100%;
  margin-left: 35px;
  //The '- 1' is needed so that at exactly 900px width we begin showing the
  //mobile header, otherwise at that exact pixel count we are not showing either
  @media (max-width: ${breakpointNum.bigtablet - 1}px) {
    display: none;
  }
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const LogoStyled = styled(Logo)`
  cursor: pointer;
  color: ${colors.iWhite};
`

export const LeftMenu = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  @media (max-width: ${breakpointNum.bigtablet - 1}px) {
    display: none;
  }
  flex-shrink: 1;
`

export const HeaderPagesLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  margin-left: 64px;
  height: 100%;
`

export const HeaderPageLink = styled.div<{ isSelected: boolean }>`
  text-align: center;
  border-bottom: 1px ${colors.iPrimaryBlue};
  border-bottom-style: ${({ isSelected }) => (isSelected ? "solid" : "")};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${T.text} {
    color: ${({ isSelected }) =>
      isSelected ? colors.iPrimaryBlue : colors.iMediumGrey};
  }
  :hover {
    ${T.text} {
      color: ${colors.iPrimaryBlue};
    }
  }
`

export const LogoutCircleRStyled = styled(LogoutCircleR)`
  width: 22px;
  fill: ${colors.iMediumGrey};
`

export const CogStyled = styled(Cog)`
  width: 22px;
  fill: ${colors.iMediumGrey};
`

export const RightOptionWrapper = styled.div<{
  isLast?: boolean
  isFirst?: boolean
}>`
  padding: ${({ isLast }) => (isLast ? "0 0 0 16px" : "0 17px 0 16px")};
  height: 32px;
  border: 0 solid ${colors.iDarkGrey};
  border-right-width: ${({ isLast }) => (isLast ? "0" : "1px")};
  border-left-width: ${({ isFirst }) => (isFirst ? "1px" : "0")};
  & > *:hover {
    fill: ${colors.iPrimaryBlue};
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const NameEmail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  flex-shrink: 5000;
  overflow: hidden;
  & > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

export const UserProfileImage = styled.img`
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: 2px solid ${colors.iPrimaryBlue};
  margin: 5px;
`

export const MobileLogoStyled = styled(Logo)`
  @media (min-width: ${breakpointNum.bigtablet}px) {
    display: none;
  }
  cursor: pointer;
  color: ${colors.iWhite};
`

export const MobileMenuStyled = styled(Menu)`
  @media (min-width: ${breakpointNum.bigtablet}px) {
    display: none;
  }
  width: 40px;
  height: 40px;
  fill: ${colors.iPrimaryBlue};
  cursor: pointer;
`
export const MobileHamburgerMenuWrapper = styled.div`
  overflow: hidden scroll;
  background-color: ${colors.iBlack};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: fixed;
  top: 0%;
  right: 0%;
`
export const MobileHamburgerMenuItem = styled(T.H2)<{ isSelected?: boolean }>`
  cursor: pointer;
  margin: 10px;
  padding-left: 40px;
  :before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 33%;
    border-bottom: 1px solid;
    border-bottom-color: ${({ isSelected }) =>
      isSelected ? colors.iPrimaryBlue : colors.iUltraDarkGrey};
  }
  position: relative;
  :hover {
    color: ${colors.iPrimaryBlue};
  }
`
export const CloseOutlineStyled = styled(CloseOutline)`
  width: 40px;
  height: 40px;
  fill: ${colors.iPrimaryBlue};
  cursor: pointer;
`
export const HamburgerHeader = styled.div`
  background-color: ${colors.iUltraDarkGrey};
  display: flex;
  justify-content: space-between;
  padding: 10px 40px 10px;
  margin-bottom: 30px;
  align-items: center;
`

export const UserCardWrapper = styled.div`
  display: flex;
  flex-grow: 50;
  align-items: center;
  padding-right: 8px;
  flex-shrink: 5000;
  overflow: hidden;
`
