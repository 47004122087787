import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import * as HighchartsStock from "highcharts/highstock"
import HighchartsBoost from "highcharts/modules/boost"
import React from "react"
import { colors } from "../../styles"

HighchartsBoost(HighchartsStock)

const defaultSeriesOptions = {
  type: "line",
  dataGrouping: {
    enabled: false,
  },
  boostThreshold: 1,
  zIndex: 6,
  lineWidth: 1,
  color: colors.iPrimaryBlue,
  animation: false,
  enableMouseTracking: true,
  states: {
    hover: {
      enabled: true,
    },
  },
  findNearestPointBy: "x",
}

const MemoizedPepGraph = ({
  chartComponentRef,
  isLast,
  isFirst,
  setExtremes,
  color,
  loadedHistorical,
  negativeColor,
  title,
  toolTipFormatter,
  labelFormatter,
}: {
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>
  precision: number
  loadedHistorical: boolean
  isLast?: boolean
  isFirst?: boolean
  setExtremes: Highcharts.AxisSetExtremesEventCallbackFunction
  color: HighchartsStock.ColorString
  negativeColor?: HighchartsStock.ColorString
  title: string
  toolTipFormatter: (yValue: number) => string
  labelFormatter?: (yValue: number) => string
}) => (
  <HighchartsReact
    highcharts={HighchartsStock}
    constructorType={"StockChart"}
    options={{
      credits: {
        enable: false,
      },
      accessibility: {
        enabled: false,
      },
      boost: {
        usePreallocated: loadedHistorical ? true : false,
      },
      title: {
        align: "left",
        text: title,
        x: 50,
        y: 19,
        style: {
          color: colors.iMediumGrey,
          "font-family": "Lato-Bold",
          "font-size": "10px",
        },
      },
      chart: {
        backgroundColor: colors.iUltraDarkGrey,
        margin: [30, 75, 30, 75],
      },

      navigator: {
        height: 25,
        enabled: isLast,
        adaptToUpdatedData: true,
      },
      scrollbar: {
        enabled: isLast,
      },
      rangeSelector: {
        adaptToUpdatedData: true,
        enabled: isFirst,
        stickToMax: true,
        floating: false,
        buttonPosition: {
          align: "right",
          y: -40,
        },
        inputEnabled: false,
        dropdown: "always",
        buttons: [
          {
            type: "minute",
            count: 5,
            text: "5m",
          },
          {
            type: "minute",
            count: 30,
            text: "30m",
          },
          {
            type: "hour",
            count: 1,
            text: "1h",
          },
          {
            type: "hour",
            count: 6,
            text: "6h",
          },
          {
            type: "hour",
            count: 12,
            text: "12h",
          },
          {
            type: "day",
            count: 1,
            text: "1d",
          },
          {
            type: "all",
            text: "All",
          },
        ],
      },
      xAxis: {
        gridLineWidth: 0,
        lineColor: colors.iDarkGrey,
        tickWidth: 0,
        type: "datetime",
        ordinal: false,
        events: {
          afterSetExtremes: setExtremes,
        },
        min: Date.now() - 1000 * 60 * 5,
        max: Date.now(),
        crosshair: {
          color: colors.iDarkGrey,
          snap: true,
        },
      },
      yAxis: [
        {
          opposite: false,
          gridLineColor: colors.iDarkGrey,
          labels: {
            formatter: function (this: any) {
              if (this.value === undefined) return ""
              else if (labelFormatter) return labelFormatter(this.value)
              else return toolTipFormatter(this.value)
            },
          },
          reserveSpace: true,
        },
        {
          endOnTick: false,
          labels: {
            x: -3,
            align: "right",
            reserveSpace: true,
            formatter: function (this: any) {
              if (this.value === undefined) return ""
              else if (labelFormatter) return labelFormatter(this.value)
              else return toolTipFormatter(this.value)
            },
          },
          gridLineColor: colors.iDarkGrey,
          linkedTo: 0,
          opposite: true,
          offset: 15,
        },
      ],
      tooltip: {
        enabled: true,
        animation: false,
        backgroundColor: colors.iUltraDarkGrey,
        borderColor: colors.iUltraDarkGrey,
        style: {
          color: colors.iWhite,
        },
        formatter: function (this: any) {
          return isLast
            ? [
                Highcharts.dateFormat("%a, %b %e, %H:%M:%S", this.x),
                toolTipFormatter(this.y || 0),
              ]
            : toolTipFormatter(this.y || 0)
        },
        headerFormat: "",
      },
      series: [
        {
          ...defaultSeriesOptions,
          zones: [{ value: 0, color: negativeColor }, { color }],
        },
      ],
    }}
    ref={chartComponentRef}
    containerProps={{
      style: {
        height: "100%",
        width: "100%",
      },
    }}
  />
)
export default React.memo(MemoizedPepGraph)
